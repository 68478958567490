import { useEffect, useMemo, useState } from 'react'

import { useGetCustomerMaskedData } from 'components/pages/api'
import { OrderStatus } from 'consts'
import { generateSHA256Digest } from 'helpers/generateExtensionToken'
import { CustomerMaskedData, CustomerMaskedDataRes } from 'models'

import { useExtensionTokenSearch } from './useExtensionTokenSearch'

interface WeBookExtension {
  status: OrderStatus | undefined
  nip: number | undefined
  isExtension: boolean | undefined
}

export const useWeBookExtension = ({ status, nip, isExtension }: WeBookExtension) => {
  const { webookExtensionTokenParam } = useExtensionTokenSearch()

  const [webookExtensionEnabledToken, setWebookExtensionEnabledToken] = useState('')

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (status) {
      const checkExtension = async () => {
        if (
          webookExtensionTokenParam &&
          isExtension &&
          nip &&
          (status === OrderStatus.PRE_ORDER || status === OrderStatus.OFFER_ACCEPTED)
        ) {
          setLoading(true)
          try {
            const token = await generateSHA256Digest(nip, 'B2B')
            if (token === webookExtensionTokenParam) {
              setWebookExtensionEnabledToken(token)
            } else {
              setLoading(false)
            }
          } catch (error) {
            setWebookExtensionEnabledToken('')
            setLoading(false)
          }
        } else {
          setLoading(false)
          setWebookExtensionEnabledToken('')
        }
      }

      checkExtension()
    }
  }, [isExtension, nip, status, webookExtensionTokenParam])

  const {
    data: customerMaskedData,
    isSuccess: customerMaskedDataIsSuccess,
    isError: customerMaskedDataIsError,
  } = useGetCustomerMaskedData(String(nip), webookExtensionTokenParam, webookExtensionEnabledToken !== '')

  useEffect(() => {
    if (webookExtensionEnabledToken && (customerMaskedDataIsSuccess || customerMaskedDataIsError)) {
      setLoading(false)
    }
  }, [webookExtensionEnabledToken, customerMaskedDataIsSuccess, customerMaskedDataIsError])

  const mappedData = useMemo(() => {
    if (customerMaskedData) {
      return replaceNullValues(customerMaskedData)
    }
    return null
  }, [customerMaskedData])

  return {
    customerMaskedData: webookExtensionEnabledToken !== '' ? mappedData : null,
    customerMaskedDataIsError,
    customerMaskedDataIsLoading: loading,
    webookExtensionEnabledToken,
  }
}

const replaceNullValues = (data: CustomerMaskedDataRes): CustomerMaskedData => {
  return {
    firstName: data.firstName ?? '',
    lastName: data.lastName ?? '',
    buyerEmail: data.buyerEmail ?? '',
    buyerPhone: data.buyerPhone ?? '',
    pesel: data.pesel ?? '',
    identityCardValidFrom: data.identityCardValidFrom ?? '',
    identityCardValidTo: data.identityCardValidTo ?? '',
    returnAccountNumber: data.returnAccountNumber ?? '',
  }
}
