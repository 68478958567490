"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  BackdropLoader: () => BackdropLoader_default,
  BasicDialog: () => BasicDialog,
  Button: () => Button_default,
  Card: () => Card,
  Checkbox: () => Checkbox_default,
  Chip: () => Chip_default,
  CodeVerification: () => CodeVerification_default,
  ControlledCheckbox: () => ControlledCheckbox,
  ControlledDatePicker: () => ControlledDatePicker,
  ControlledSelect: () => ControlledSelect,
  ControlledTextField: () => ControlledTextField,
  Currency: () => Currency_default,
  CustomAccordion: () => CustomAccordion,
  CustomLink: () => CustomLink_default,
  DatePicker: () => DatePicker,
  EditButton: () => EditButton_default,
  FailedDialog: () => FailedDialog,
  FieldWithButton: () => FieldWithButton_default,
  Header: () => Header_default,
  InfoBar: () => InfoBar,
  InputLabel: () => InputLabel_default,
  LinearProgress: () => LinearProgress,
  LoadingDialog: () => LoadingDialog,
  MainAccordion: () => MainAccordion,
  PhoneNumber: () => PhoneNumber_default,
  RadioAccordionGroup: () => RadioAccordionGroup,
  RequiredSign: () => RequiredSign_default,
  Select: () => Select,
  SimpleCard: () => SimpleCard,
  Spinner: () => Spinner,
  StepTitle: () => StepTitle_default,
  Stepper: () => Stepper_default,
  StyledBackdrop: () => StyledBackdrop,
  TextField: () => TextField
});
module.exports = __toCommonJS(src_exports);

// src/atoms/Button/index.tsx
var import_material = require("@mui/material");
var import_jsx_runtime = require("react/jsx-runtime");
var Button = ({
  children,
  sx = [],
  variant = "contained",
  color,
  loading,
  disabled,
  startIcon,
  endIcon,
  ...rest
}) => /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material.Button, {
  variant,
  color,
  startIcon,
  endIcon,
  ...rest,
  disabled: disabled || loading,
  sx: [{}, ...Array.isArray(sx) ? sx : [sx]],
  children: [
    children,
    loading && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material.CircularProgress, {
      size: 24,
      color,
      sx: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: "-12px",
        marginLeft: "-12px"
      }
    })
  ]
});
var Button_default = Button;

// src/atoms/Checkbox/index.tsx
var import_material2 = require("@mui/material");

// src/assets/icons/Box.svg
var React = __toESM(require("react"));
var import_jsx_runtime = require("react/jsx-runtime");
var SvgBox = (props) => /* @__PURE__ */ (0, import_jsx_runtime.jsxs)("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props,
  children: [
    /* @__PURE__ */ (0, import_jsx_runtime.jsxs)("g", {
      filter: "url(#filter0_d_527_489)",
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime.jsx)("rect", {
          x: 2,
          y: 1,
          width: 16,
          height: 16,
          rx: 4,
          fill: "white"
        }),
        /* @__PURE__ */ (0, import_jsx_runtime.jsx)("rect", {
          x: 2.5,
          y: 1.5,
          width: 15,
          height: 15,
          rx: 3.5,
          stroke: "#CCD4E0"
        })
      ]
    }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("defs", {
      children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)("filter", {
        id: "filter0_d_527_489",
        x: 0,
        y: 0,
        width: 20,
        height: 20,
        filterUnits: "userSpaceOnUse",
        colorInterpolationFilters: "sRGB",
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feFlood", {
            floodOpacity: 0,
            result: "BackgroundImageFix"
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feColorMatrix", {
            in: "SourceAlpha",
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
            result: "hardAlpha"
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feOffset", {
            dy: 1
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feGaussianBlur", {
            stdDeviation: 1
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feComposite", {
            in2: "hardAlpha",
            operator: "out"
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feColorMatrix", {
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feBlend", {
            mode: "normal",
            in2: "BackgroundImageFix",
            result: "effect1_dropShadow_527_489"
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feBlend", {
            mode: "normal",
            in: "SourceGraphic",
            in2: "effect1_dropShadow_527_489",
            result: "shape"
          })
        ]
      })
    })
  ]
});
var Box_default = SvgBox;

// src/assets/icons/CheckedBox.svg
var React2 = __toESM(require("react"));
var import_jsx_runtime = require("react/jsx-runtime");
var SvgCheckedBox = (props) => /* @__PURE__ */ (0, import_jsx_runtime.jsxs)("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props,
  children: [
    /* @__PURE__ */ (0, import_jsx_runtime.jsxs)("g", {
      filter: "url(#filter0_d_534_744)",
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime.jsx)("rect", {
          x: 2,
          y: 1,
          width: 16,
          height: 16,
          rx: 4,
          fill: "currentColor"
        }),
        /* @__PURE__ */ (0, import_jsx_runtime.jsx)("path", {
          d: "M9.18199 10.5913L13.7782 5.99512L14.4853 6.70222L9.18199 12.0055L6 8.82356L6.70711 8.11646L9.18199 10.5913Z",
          fill: "white"
        })
      ]
    }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("defs", {
      children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)("filter", {
        id: "filter0_d_534_744",
        x: 0,
        y: 0,
        width: 20,
        height: 20,
        filterUnits: "userSpaceOnUse",
        colorInterpolationFilters: "sRGB",
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feFlood", {
            floodOpacity: 0,
            result: "BackgroundImageFix"
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feColorMatrix", {
            in: "SourceAlpha",
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
            result: "hardAlpha"
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feOffset", {
            dy: 1
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feGaussianBlur", {
            stdDeviation: 1
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feComposite", {
            in2: "hardAlpha",
            operator: "out"
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feColorMatrix", {
            type: "matrix",
            values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feBlend", {
            mode: "normal",
            in2: "BackgroundImageFix",
            result: "effect1_dropShadow_534_744"
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)("feBlend", {
            mode: "normal",
            in: "SourceGraphic",
            in2: "effect1_dropShadow_534_744",
            result: "shape"
          })
        ]
      })
    })
  ]
});
var CheckedBox_default = SvgCheckedBox;

// src/atoms/Checkbox/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var checkboxStyles = (size) => {
  return {
    p: 0,
    mr: 0.8,
    ml: 1.2,
    height: "100%",
    "& > svg": {
      width: size,
      height: size
    }
  };
};
var Checkbox = ({ disabled, name = "checkbox-field", size = "1.6rem", sx = [], ...rest }) => {
  const { palette } = (0, import_material2.useTheme)();
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material2.Checkbox, {
    icon: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Box_default, {}),
    checkedIcon: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(CheckedBox_default, {
      fill: disabled ? palette.text.disabled : palette.primary.main
    }),
    disabled,
    name,
    sx: [checkboxStyles(size), ...Array.isArray(sx) ? sx : [sx]],
    ...rest
  });
};
var Checkbox_default = Checkbox;

// src/atoms/Chip/index.tsx
var import_react = require("react");
var import_material3 = require("@mui/material");
var import_jsx_runtime = require("react/jsx-runtime");
var chipCommonStyle = {
  fontWeight: 700
};
var Chip = (0, import_react.memo)(({ sx = [], variant = "outlined", size = "small", ...props }) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material3.Chip, {
  sx: [chipCommonStyle, ...Array.isArray(sx) ? sx : [sx]],
  variant,
  size,
  ...props
}));
var Chip_default = Chip;

// src/atoms/Currency/index.tsx
var import_react2 = require("react");
var import_material4 = require("@mui/material");

// src/helpers/formatCurrency.ts
var formatCurrency = (amount, showDecimals, currency, net, mth) => {
  let formattedAmount = Number(amount).toFixed(showDecimals ? 2 : 0);
  if (currency)
    formattedAmount += ` ${currency}`;
  if (net)
    formattedAmount += ` ${net}`;
  if (mth)
    formattedAmount += `/${mth}`;
  return formattedAmount;
};

// src/helpers/formatPhoneNumber.ts
var formatPhoneNumber = (phoneNumber, separator) => {
  var _a3;
  const sep = separator != null ? separator : "-";
  const number = (_a3 = phoneNumber == null ? void 0 : phoneNumber.trim().replace(/[^0-9]/g, "")) != null ? _a3 : "";
  if (number.length < 4)
    return number;
  if (number.length < 7)
    return number.replace(/(\d{3})(\d{1})/, `$1${sep}$2`);
  if (number.length < 11)
    return number.replace(/(\d{3})(\d{3})(\d{1})/, `$1${sep}$2${sep}$3`);
  return number.replace(/(\d{3})(\d{4})(\d{4})/, `$1${sep}$2${sep}$3`);
};
var formatPhoneNumberWithoutSeparator = (phoneNumber) => {
  return phoneNumber.trim().replace(/[^0-9]/g, "").slice(0, 9);
};

// src/helpers/formatIban.tsx
var formatIban = (inputIBAN) => {
  const ibanWithoutSpaces = inputIBAN ? inputIBAN.trim().replace(/[^\d]/g, "") : "";
  const formattedIBAN = ibanWithoutSpaces.replace(/(\d{2})(?=\d)/, "$1 ").replace(/(\d{4})(?=\d)/g, "$1 ").trim();
  return formattedIBAN;
};
var formatIbanWithoutSpaces = (inputIBAN) => {
  return inputIBAN ? inputIBAN.trim().replace(/[^\d]/g, "").slice(0, 26) : "";
};

// src/atoms/Currency/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var Currency = (0, import_react2.memo)(
  ({ formatMessage, value, currency = "PLN", net = "net", mth = true, showDecimals = true, sx = [] }) => {
    const formatMsg = typeof formatMessage === "function" ? formatMessage : ({ id }) => id;
    const currencyName = currency ? formatMsg({ id: currency }) : null;
    const netName = net ? formatMsg({ id: net }) : null;
    const mthName = mth ? formatMsg({ id: "mth" }) : null;
    return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material4.Box, {
      component: "span",
      sx: [{}, ...Array.isArray(sx) ? sx : [sx]],
      children: formatCurrency(value, showDecimals, currencyName, netName, mthName)
    });
  }
);
var Currency_default = Currency;

// src/atoms/CustomLink/index.tsx
var import_Link = __toESM(require("@mui/material/Link"));
var import_Typography = __toESM(require("@mui/material/Typography"));
var import_jsx_runtime = require("react/jsx-runtime");
var CustomLink = ({ title, btnTitle, fnCallback, disabled, id, sx = [] }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_Typography.default, {
    variant: "body2",
    color: "text.secondary",
    sx: [{}, ...Array.isArray(sx) ? sx : [sx]],
    children: [
      title,
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Link.default, {
        id,
        component: "button",
        variant: "inherit",
        onClick: (e) => {
          e.preventDefault();
          fnCallback();
        },
        color: "text.primary",
        disabled,
        sx: { ml: 0.5 },
        children: btnTitle
      })
    ]
  });
};
var CustomLink_default = CustomLink;

// src/atoms/Header/index.tsx
var import_react3 = require("react");
var import_react_resize_detector = require("react-resize-detector");
var import_material5 = require("@mui/material");
var import_Box2 = __toESM(require("@mui/material/Box"));
var import_Container = __toESM(require("@mui/material/Container"));

// src/atoms/Header/styles.tsx
var headerContainerStyle = {
  mb: 3,
  bgcolor: "common.white",
  zIndex: 1,
  position: "sticky",
  top: -1,
  transition: "transform .2s ease-in-out"
};
var headerStyle = {
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
  alignItems: "center",
  position: "relative",
  height: "100%"
};
var dividerStyle = (headerHeight) => ({
  position: "sticky",
  top: headerHeight,
  mt: -0.2
});
var subHeaderLongDividerStyle = (visible, headerHeight) => ({
  position: "sticky",
  zIndex: 1,
  top: Number(headerHeight) - 3,
  transition: "transform .2s ease-in-out",
  transform: visible ? "none" : `translateY(-${Number(headerHeight) + 2}px)`
});
var headerImgStyle = {
  display: "flex",
  "& img": {
    height: 64,
    width: "auto",
    py: 1
  }
};

// src/atoms/Header/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var Header = (0, import_react3.memo)(({ img, sx = [], subHeader, children }) => {
  const imgs = Array.isArray(img) ? img : [img];
  const theme = (0, import_material5.useTheme)();
  const matchesDownMd = (0, import_material5.useMediaQuery)(theme.breakpoints.down("md"));
  const { height: headerHeight, ref: headerRef } = (0, import_react_resize_detector.useResizeDetector)();
  const [prevScrollPos, setPrevScrollPos] = (0, import_react3.useState)(0);
  const [visible, setVisible] = (0, import_react3.useState)(true);
  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    if (matchesDownMd) {
      if (currentScrollPos > prevScrollPos && currentScrollPos > Number(headerHeight)) {
        setVisible(false);
      } else {
        setVisible(true);
      }
      setPrevScrollPos(currentScrollPos);
    }
  };
  (0, import_react3.useEffect)(() => {
    setVisible(true);
  }, [headerHeight]);
  (0, import_react3.useEffect)(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_jsx_runtime.Fragment, {
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_Box2.default, {
        sx: [
          headerContainerStyle,
          { transform: visible ? "none" : "translateY(-100%)", mb: subHeader ? 0 : 3 },
          ...Array.isArray(sx) ? sx : [sx]
        ],
        ref: headerRef,
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_Box2.default, {
            sx: { minHeight: 65 },
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_Container.default, {
                sx: headerStyle,
                children: [
                  /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Box2.default, {
                    sx: headerImgStyle,
                    mt: { xs: children ? 1 : 0, md: 0 },
                    children: imgs.map((el, idx) => {
                      var _a3;
                      const altText = (_a3 = el == null ? void 0 : el.alt) != null ? _a3 : `Img${idx}`;
                      return (el == null ? void 0 : el.src) && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Box2.default, {
                        sx: { display: "flex", ml: idx === 0 ? 0 : 0.4 },
                        children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("img", {
                          src: el == null ? void 0 : el.src,
                          alt: altText,
                          width: 130
                        })
                      }, altText);
                    })
                  }),
                  children
                ]
              }),
              subHeader && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Container.default, {
                sx: { transform: visible ? "none" : "translateY(-100%)" },
                children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material5.Divider, {
                  sx: dividerStyle(Number(headerHeight))
                })
              })
            ]
          }),
          !subHeader && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material5.Divider, {
            sx: dividerStyle(Number(headerHeight))
          })
        ]
      }),
      subHeader,
      subHeader && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Box2.default, {
        sx: subHeaderLongDividerStyle(visible, Number(headerHeight)),
        mb: 3,
        children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material5.Divider, {})
      })
    ]
  });
});
var Header_default = Header;

// src/atoms/InputLabel/index.tsx
var import_react4 = require("react");
var import_material6 = require("@mui/material");
var import_jsx_runtime = require("react/jsx-runtime");
var labelStyle = {
  typography: "subtitle2",
  color: "text.primary",
  fontWeight: 700,
  mb: 0.8
};
var InputLabel = (0, import_react4.memo)(({ id, sx = [], children }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material6.InputLabel, {
    htmlFor: id,
    sx: [labelStyle, ...Array.isArray(sx) ? sx : [sx]],
    children
  });
});
var InputLabel_default = InputLabel;

// src/atoms/RequiredSign/index.tsx
var import_react5 = require("react");
var import_material7 = require("@mui/material");
var import_jsx_runtime = require("react/jsx-runtime");
var RequiredSign = (0, import_react5.memo)(({ sx = [] }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material7.Typography, {
    variant: "inherit",
    component: "span",
    color: "error.main",
    sx: [{}, ...Array.isArray(sx) ? sx : [sx]],
    children: "*"
  });
});
var RequiredSign_default = RequiredSign;

// src/atoms/StepTitle/index.tsx
var import_Box3 = __toESM(require("@mui/material/Box"));
var import_Typography2 = __toESM(require("@mui/material/Typography"));
var import_jsx_runtime = require("react/jsx-runtime");
var StepTitle = ({ title, subtitle, children }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_Box3.default, {
    mb: { xs: 3.2, md: 4.8 },
    children: [
      typeof title === "string" ? /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Typography2.default, {
        variant: "h2",
        children: title
      }) : title,
      subtitle && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Typography2.default, {
        color: "text.secondary",
        mt: 0.8,
        children: subtitle
      }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Box3.default, {
        mt: 1.6,
        children
      })
    ]
  });
};
var StepTitle_default = StepTitle;

// src/atoms/cards/Card/index.tsx
var import_react6 = require("react");
var import_material9 = require("@mui/material");

// src/atoms/cards/Card/styles.tsx
var import_material8 = require("@mui/material");
var StyledImg = (0, import_material8.styled)(import_material8.Box, {
  shouldForwardProp: (prop) => prop !== "src"
})(({ src }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  height: "100%",
  width: "100%"
}));
var StyledImgBox = (0, import_material8.styled)(import_material8.Box)(({ theme }) => ({
  width: "4.8rem",
  minWidth: "4.8rem",
  height: "4.8rem",
  minHeight: "4.8rem",
  display: "flex",
  alignItems: "center",
  marginRight: "1.8rem",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    marginRight: "1.6rem"
  }
}));

// src/atoms/cards/Card/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var Card = (0, import_react6.memo)(({ children, title, img, variant = "body1", sx = [] }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material9.Box, {
    sx: [{ display: "flex" }, { alignItems: "flex-start" }, ...Array.isArray(sx) ? sx : [sx]],
    children: [
      img && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(StyledImgBox, {
        children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(StyledImg, {
          src: img,
          "data-testid": "cardImg"
        })
      }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material9.Box, {
        display: "flex",
        flexDirection: children ? "column" : "row",
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material9.Typography, {
            variant,
            children: title != null ? title : ""
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material9.Box, {
            mt: 0.8,
            children
          })
        ]
      })
    ]
  });
});

// src/atoms/cards/SimpleCard/index.tsx
var import_react7 = require("react");
var import_material10 = require("@mui/material");
var import_jsx_runtime = require("react/jsx-runtime");
var iconBoxStyle = {
  display: "flex",
  color: "text.secondary",
  "& svg": { width: "auto", height: "3.2rem" },
  mb: 0.8
};
var SimpleCard = (0, import_react7.memo)(({ title, subtitle, icon, sx = [] }) => {
  const Icon = icon;
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material10.Box, {
    sx: [{ maxWidth: "17.4rem" }, ...Array.isArray(sx) ? sx : [sx]],
    children: [
      Icon && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material10.Box, {
        sx: iconBoxStyle,
        children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Icon, {
          fill: "inherit"
        })
      }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material10.Typography, {
        variant: "h5",
        mb: 1.6,
        children: title
      }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material10.Typography, {
        variant: "subtitle2",
        children: subtitle
      })
    ]
  });
});

// src/atoms/fields/TextField/index.tsx
var import_react21 = require("react");
var import_material25 = require("@mui/material");

// src/assets/icons/FlagPL.svg
var React3 = __toESM(require("react"));
var import_jsx_runtime = require("react/jsx-runtime");
var SvgFlagPl = (props) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)("svg", {
  width: 20,
  height: 15,
  viewBox: "0 0 20 15",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props,
  children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)("g", {
    id: "flag",
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)("mask", {
        id: "mask0_893_38",
        style: {
          maskType: "luminance"
        },
        maskUnits: "userSpaceOnUse",
        x: 0,
        y: 0,
        width: 20,
        height: 15,
        children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("rect", {
          id: "mask",
          width: 20,
          height: 15,
          fill: "white"
        })
      }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)("g", {
        mask: "url(#mask0_893_38)",
        children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)("g", {
          id: "contents",
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)("path", {
              id: "background",
              fillRule: "evenodd",
              clipRule: "evenodd",
              d: "M0 0V15H20V0H0Z",
              fill: "#F7FCFF"
            }),
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)("mask", {
              id: "mask1_893_38",
              style: {
                maskType: "luminance"
              },
              maskUnits: "userSpaceOnUse",
              x: 0,
              y: 0,
              width: 20,
              height: 15,
              children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("path", {
                id: "background_2",
                fillRule: "evenodd",
                clipRule: "evenodd",
                d: "M0 0V15H20V0H0Z",
                fill: "white"
              })
            }),
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)("g", {
              mask: "url(#mask1_893_38)",
              children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("path", {
                id: "top",
                fillRule: "evenodd",
                clipRule: "evenodd",
                d: "M0 7.5V15H20V7.5H0Z",
                fill: "#C51918"
              })
            })
          ]
        })
      })
    ]
  })
});
var FlagPL_default = SvgFlagPl;

// src/assets/icons/InfoCircle.svg
var React4 = __toESM(require("react"));
var import_jsx_runtime = require("react/jsx-runtime");
var SvgInfoCircle = (props) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props,
  children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("path", {
    d: "M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9 5H11V7H9V5ZM9 9H11V15H9V9Z",
    fill: "currentColor"
  })
});
var InfoCircle_default = SvgInfoCircle;

// src/molecules/BackdropLoader/index.tsx
var import_material11 = require("@mui/material");
var import_Backdrop = __toESM(require("@mui/material/Backdrop"));
var import_jsx_runtime = require("react/jsx-runtime");
var StyledBackdrop = (0, import_material11.styled)(import_Backdrop.default)(
  ({ theme }) => theme.unstable_sx({
    color: "primary.main",
    zIndex: theme.zIndex.drawer + 1,
    bgcolor: "rgba(157, 157, 157, 0.50)",
    backdropFilter: "blur(.6rem)"
  })
);
var BackdropLoader = ({ sx = [] }) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)(StyledBackdrop, {
  sx: [{}, ...Array.isArray(sx) ? sx : [sx]],
  open: true,
  "data-testid": "backdrop-loader",
  children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Spinner, {
    color: "inherit"
  })
});
var BackdropLoader_default = BackdropLoader;

// src/molecules/CodeVerification/index.tsx
var import_react10 = require("react");
var import_react_intl = require("react-intl");
var import_material17 = require("@mui/material");

// src/molecules/PhoneNumber/index.tsx
var import_react8 = require("react");
var import_material12 = require("@mui/material");

// src/config/theme/breakpoints.ts
var breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1400,
    xxl: 1600
  }
};

// src/config/theme/colors.ts
var colors = {
  primary: {
    light: "#DB1464",
    main: "#F30464",
    dark: "#DB1464"
  },
  secondary: {
    main: "#2522a1"
  },
  success: {
    main: "#22D263"
  },
  warning: {
    main: "#F96818"
  },
  error: {
    main: "#F34040"
  },
  background: {
    paper: "#fff",
    default: "#F9FAFB"
  },
  text: {
    primary: "#101C2D",
    secondary: "#66768E",
    disabled: "#667085"
  },
  border: {
    default: "#EAECF0"
  },
  grey: {
    50: "#F9FAFB",
    100: "#F1F4F9",
    200: "#DDE4EE",
    300: "#CCD4E0",
    400: "#98A2B3",
    500: "#66768E",
    600: "#475467",
    700: "#344054",
    800: "#1D2939",
    900: "#101C2D"
  }
};

// src/config/theme/components/MuiButton.tsx
var MuiButton2 = {
  styleOverrides: {
    root: {
      textTransform: "none",
      padding: "1.2rem 1.6rem"
    },
    contained: {
      color: "white"
    },
    sizeMedium: {
      minWidth: "unset"
    },
    sizeSmall: {
      padding: "1.2rem 1.6rem"
    }
  }
};

// src/config/theme/components/MuiChip.tsx
var MuiChip2 = {
  styleOverrides: {
    root: {
      borderRadius: ".8rem"
    }
  }
};

// src/config/theme/components/MuiCssBaseline.tsx
var MuiCssBaseline = {
  styleOverrides: {
    html: {
      scrollBehavior: "smooth",
      fontSize: "10px",
      "@media (max-width: 900px)": {
        fontSize: "9.25px"
      },
      "@media (max-width: 600px)": {
        fontSize: "8.75px"
      },
      "& *": {
        scrollbarWidth: "thin",
        scrollbarColor: `${colors.grey[500]} ${colors.grey[200]}`
      },
      "& *::-webkit-scrollbar": {
        width: ".4rem",
        height: ".4rem"
      },
      "& *::-webkit-scrollbar-track": {
        background: colors.grey[200],
        borderRadius: ".8rem"
      },
      "& *::-webkit-scrollbar-thumb": {
        backgroundColor: colors.grey[500],
        borderRadius: ".8rem"
      }
    }
  }
};

// src/config/theme/helpers.tsx
function pxToRem(value, baseValue = 10) {
  return `${value / baseValue}rem`;
}

// src/config/theme/typography.ts
var customFontFamily = '"Manrope", "Roboto", "Montserrat", "Helvetica", "Arial", sans-serif';
var _a;
var typography = {
  htmlFontSize: 10,
  fontFamily: '"Roboto", "Montserrat", "Helvetica", "Arial", sans-serif',
  h1: {
    fontSize: pxToRem(40),
    lineHeight: 1.4,
    fontWeight: 700
  },
  h2: {
    fontSize: pxToRem(28),
    lineHeight: 1.429,
    fontWeight: 700,
    fontFamily: customFontFamily
  },
  h3: {
    fontSize: pxToRem(24),
    lineHeight: 1.25,
    fontWeight: 700
  },
  h4: {
    fontSize: pxToRem(20),
    lineHeight: 1.4,
    fontWeight: 700,
    fontFamily: customFontFamily
  },
  h5: {
    fontSize: pxToRem(18),
    lineHeight: 1.333,
    fontWeight: 700,
    fontFamily: customFontFamily,
    letterSpacing: "0.02rem"
  },
  h6: {
    fontSize: pxToRem(16),
    lineHeight: 1.625,
    fontWeight: 700,
    fontFamily: customFontFamily,
    letterSpacing: "0.02rem"
  },
  subtitle1: {
    fontSize: pxToRem(16),
    lineHeight: 1.625,
    fontWeight: 700
  },
  subtitle2: {
    fontSize: pxToRem(12),
    lineHeight: 1.333,
    color: (_a = colors.text) == null ? void 0 : _a.secondary
  },
  body1: {
    fontSize: pxToRem(16),
    lineHeight: 1.625
  },
  body2: {
    fontSize: pxToRem(14),
    lineHeight: 1.571
  },
  button: {
    fontSize: pxToRem(16),
    lineHeight: 1.857,
    fontWeight: 700
  },
  caption: {
    fontSize: pxToRem(12),
    lineHeight: 1.333
  },
  overline: {
    fontSize: pxToRem(13)
  }
};

// src/config/theme/components/MuiOutlinedInput.tsx
var MuiOutlinedInput = {
  styleOverrides: {
    input: ({ theme }) => {
      var _a3;
      return {
        ...theme.typography.body2,
        background: (_a3 = theme.palette.background) == null ? void 0 : _a3.paper,
        padding: "1.1rem 1.6rem",
        "&.Mui-disabled": {
          background: theme.palette.grey[100]
        }
      };
    },
    root: ({ ownerState, theme }) => {
      return {
        ...typography.body2,
        "&.Mui-disabled": {
          background: theme.palette.grey[100]
        },
        ...!ownerState.error && !ownerState.disabled && {
          "&:hover fieldset": {
            borderColor: `${theme.palette.grey[500]} !important`
          }
        },
        ...ownerState.focused && !ownerState.error && {
          "& fieldset": {
            borderColor: `${theme.palette.grey[500]} !important`
          }
        }
      };
    }
  }
};

// src/config/theme/components/MuiPaper.tsx
var _a2;
var MuiPaper = {
  styleOverrides: {
    root: {
      border: `.1rem solid ${(_a2 = colors.grey) == null ? void 0 : _a2[200]}`
    }
  }
};

// src/config/theme/components/index.tsx
var components = {
  MuiButton: MuiButton2,
  MuiPaper,
  MuiOutlinedInput,
  MuiChip: MuiChip2,
  MuiCssBaseline,
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true
    }
  }
};

// src/config/theme/otherConfig.ts
var otherConfig = {
  shadows: Array(25).fill("none"),
  spacing: 10,
  shape: {
    borderRadius: 8
  },
  functions: {
    pxToRem
  }
};

// src/config/theme/index.ts
var muiConfig = {
  breakpoints,
  colors,
  typography,
  components,
  otherConfig,
  customFontFamily
};

// src/molecules/PhoneNumber/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var PhoneNumber = (0, import_react8.memo)(({ phoneCode, phoneNo }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material12.Typography, {
    variant: "h5",
    fontWeight: 700,
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material12.Typography, {
        component: "span",
        variant: "body2",
        fontWeight: 700,
        fontFamily: muiConfig.customFontFamily,
        sx: { pr: 0.5 },
        children: phoneCode
      }),
      formatPhoneNumber(phoneNo.toString(), " ")
    ]
  });
});
var PhoneNumber_default = PhoneNumber;

// src/molecules/dialogs/BasicDialog/index.tsx
var import_material14 = require("@mui/material");

// src/molecules/dialogs/styles.tsx
var import_material13 = require("@mui/material");
var StyledModal = (0, import_material13.styled)(import_material13.Dialog)(
  ({ theme }) => theme.unstable_sx({
    position: "fixed",
    zIndex: 1300,
    inset: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    m: 1.6
  })
);
var dialogPaperStyle = {
  zIndex: (theme) => theme.zIndex.drawer + 2,
  bgcolor: "common.white",
  borderRadius: 1,
  border: (theme) => `.1rem solid ${theme.palette.grey[200]}`,
  minWidth: { xs: "80%", sm: "70%", md: 772 },
  textAlign: "center"
};
var dialogActionsStyle = {
  justifyContent: "space-around",
  mt: 6
};

// src/molecules/dialogs/BasicDialog/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var BasicDialog = ({ children, open, title, paperSxProps, actions, loading, ...otherProps }) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material14.Dialog, {
  "aria-labelledby": "dialog-title",
  "aria-describedby": "dialog-description",
  open,
  slots: {
    backdrop: StyledBackdrop
  },
  PaperProps: {
    sx: { ...dialogPaperStyle, ...paperSxProps || {} }
  },
  ...otherProps,
  children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material14.Box, {
    py: 4.8,
    px: 3.2,
    children: [
      loading && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Spinner, {
        sx: { mb: title || children ? { xs: 4, md: 5 } : 0 }
      }),
      title && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material14.Typography, {
        variant: "h2",
        component: "h3",
        id: "dialog-title",
        sx: { px: 1.6, mb: loading ? 1.6 : { xs: 4, md: 5 } },
        children: title
      }),
      children && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material14.DialogContent, {
        sx: { p: 0 },
        children
      }),
      actions && actions.length > 0 && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material14.DialogActions, {
        sx: dialogActionsStyle,
        children: actions.map((el, i) => {
          var _a3, _b, _c;
          return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(Button_default, {
            variant: (_a3 = el.variant) != null ? _a3 : "contained",
            color: (_b = el.color) != null ? _b : "primary",
            sx: [{ minWidth: "12rem" }, (_c = el.actionStyle) != null ? _c : {}],
            ...el,
            onClick: el.onClick,
            children: [
              el.label,
              el.icon
            ]
          }, `${el.label}-${i}`);
        })
      })
    ]
  })
});

// src/molecules/dialogs/FailedDialog/index.tsx
var import_material15 = require("@mui/material");

// src/assets/icons/SadEmoji.svg
var React5 = __toESM(require("react"));
var import_jsx_runtime = require("react/jsx-runtime");
var SvgSadEmoji = (props) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)("svg", {
  width: 100,
  height: 100,
  viewBox: "0 0 100 100",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props,
  children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("g", {
    id: "Frame",
    children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("path", {
      id: "Vector",
      d: "M49.9997 8.3335C73.0113 8.3335 91.6664 26.9883 91.6664 50.0002C91.6664 53.0277 91.3434 55.9797 90.7301 58.8239L83.3043 51.3981C83.3234 50.9343 83.333 50.4685 83.333 50.0002C83.333 31.5907 68.4093 16.6668 49.9997 16.6668C31.5902 16.6668 16.6663 31.5907 16.6663 50.0002C16.6663 68.4098 31.5902 83.3335 49.9997 83.3335C55.4259 83.3335 60.5493 82.0368 65.0772 79.7368C65.7226 80.7618 66.4913 81.7289 67.3813 82.6185C68.6409 83.8781 70.0518 84.8939 71.5555 85.666C65.2672 89.4739 57.8897 91.6668 49.9997 91.6668C26.9878 91.6668 8.33301 73.0118 8.33301 50.0002C8.33301 26.9883 26.9878 8.3335 49.9997 8.3335ZM79.1663 59.0485L85.0588 64.941C88.3134 68.1952 88.3134 73.4718 85.0588 76.726C81.8047 79.9806 76.528 79.9806 73.2738 76.726C70.1672 73.6198 70.0263 68.671 72.8501 65.3968L73.2738 64.941L79.1663 59.0485ZM49.9997 62.5002C56.1097 62.5002 61.6051 65.1302 65.4159 69.3202L61.4784 72.9006C58.1872 71.5939 54.2413 70.8335 49.9997 70.8335C45.758 70.8335 41.8122 71.5939 38.5208 72.9006L34.5834 69.3202C38.3942 65.1302 43.8897 62.5002 49.9997 62.5002ZM35.4163 41.6668C38.8681 41.6668 41.6663 44.4652 41.6663 47.9168C41.6663 51.3685 38.8681 54.1668 35.4163 54.1668C31.9646 54.1668 29.1663 51.3685 29.1663 47.9168C29.1663 44.4652 31.9646 41.6668 35.4163 41.6668ZM64.583 41.6668C68.0347 41.6668 70.833 44.4652 70.833 47.9168C70.833 51.3685 68.0347 54.1668 64.583 54.1668C61.1313 54.1668 58.333 51.3685 58.333 47.9168C58.333 44.4652 61.1313 41.6668 64.583 41.6668Z",
      fill: "currentColor"
    })
  })
});
var SadEmoji_default = SvgSadEmoji;

// src/molecules/dialogs/FailedDialog/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var FailedDialog = ({ children, title, open, infoBar, actions, ...otherProps }) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)(BasicDialog, {
  open,
  actions,
  ...otherProps,
  children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_jsx_runtime.Fragment, {
    children: [
      infoBar && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(InfoBar, {
        ...infoBar,
        sx: { mb: 6 }
      }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material15.Box, {
        sx: { color: "grey.200", mb: 6 },
        children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(SadEmoji_default, {
          fill: "inherit"
        })
      }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material15.Typography, {
        variant: "h2",
        component: "h3",
        id: "dialog-title",
        sx: { px: 1.6 },
        children: title
      }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material15.Box, {
        mt: 1.6,
        children
      })
    ]
  })
});

// src/molecules/dialogs/LoadingDialog/index.tsx
var import_material16 = require("@mui/material");

// src/hooks/useCountdownTimer.tsx
var import_react9 = require("react");
var useCountdownTimer = ({ minutes, seconds = 0, timerValue, timeIsOver, hideSecZeros, callbackFn }) => {
  const [remainingTime, setRemainingTime] = (0, import_react9.useState)(timerValue != null ? timerValue : minutes * 60 + seconds);
  const remainingMinutes = Math.floor(remainingTime / 60);
  const remainingSeconds = remainingTime % 60;
  (0, import_react9.useEffect)(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prevTime) => prevTime > 0 ? prevTime - 1 : 0);
    }, 1e3);
    if (timeIsOver) {
      setRemainingTime(0);
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [minutes, seconds, timeIsOver]);
  (0, import_react9.useEffect)(() => {
    if (remainingTime === 0 && typeof callbackFn === "function") {
      callbackFn();
    }
  }, [remainingTime]);
  return {
    remainingTime,
    remainingMinutes: remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes,
    remainingSeconds: hideSecZeros ? remainingSeconds : remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
  };
};

// src/molecules/dialogs/LoadingDialog/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var LoadingDialog = ({
  children,
  title,
  subtitle,
  open,
  infoBar,
  timerMinutes,
  timeIsOver,
  ...otherProps
}) => {
  const { remainingMinutes, remainingSeconds } = useCountdownTimer({ minutes: timerMinutes, timeIsOver });
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(BasicDialog, {
    open,
    ...otherProps,
    children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_jsx_runtime.Fragment, {
      children: [
        infoBar && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(InfoBar, {
          ...infoBar,
          sx: { mb: 4.6 }
        }),
        /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material16.Typography, {
          variant: "h1",
          component: "p",
          color: "primary",
          sx: { pb: 4 },
          children: [
            remainingMinutes,
            ":",
            remainingSeconds
          ]
        }),
        /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material16.Typography, {
          variant: "h2",
          component: "h3",
          id: "dialog-title",
          sx: { px: 1.6 },
          children: title
        }),
        subtitle && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material16.Typography, {
          variant: "h4",
          component: "h4",
          id: "dialog-subtitle",
          sx: { mt: 2.5, mb: 1.5, px: 1.6 },
          children: subtitle
        }),
        /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material16.Box, {
          mt: 1.6,
          children: [
            children,
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(LinearProgress, {
              timeIsOver,
              timerValue: timerMinutes * 60 * 1e3,
              sx: { mt: 4.6 }
            })
          ]
        })
      ]
    })
  });
};

// src/molecules/CodeVerification/styles.tsx
var commonInputStyle = {
  m: { xs: 0.6, sm: 1 },
  width: "5rem",
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none"
  },
  "& input[type=number]": {
    MozAppearance: "textfield"
  }
};

// src/molecules/CodeVerification/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var CodeVerification = ({
  title,
  phoneNo,
  onChangePhoneNo,
  onResendCode,
  onSubmitCode,
  errorMsg,
  loading,
  btnTitle = "button.verify"
}) => {
  const { formatMessage } = (0, import_react_intl.useIntl)();
  const [code, setCode] = (0, import_react10.useState)(["", "", "", ""]);
  const inputsRef = (0, import_react10.useRef)([]);
  const handleChange = (event, idx) => {
    const value = event.target.value;
    if (/^[0-9]$/.test(value)) {
      const newCode = [...code];
      newCode[idx] = value;
      setCode(newCode);
      const firstEmptyIndex = newCode.findIndex((digit) => digit === "");
      if (firstEmptyIndex !== -1) {
        inputsRef.current[firstEmptyIndex].focus();
      }
    }
  };
  const handleKeyDown = (event, idx) => {
    if (event.key === "Backspace") {
      const newCode = [...code];
      if (newCode[idx]) {
        newCode[idx] = "";
        setCode(newCode);
      } else if (idx > 0) {
        newCode[idx - 1] = "";
        setCode(newCode);
        inputsRef.current[idx - 1].focus();
      }
    } else if (event.key === "Enter") {
      event.preventDefault();
      if (code.indexOf("") === -1) {
        handleSendCode();
      }
    }
  };
  const handleSendCode = () => {
    const verificationCode = code.join("");
    onSubmitCode(verificationCode);
  };
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(BasicDialog, {
    open: true,
    title,
    children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material17.Box, {
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material17.Typography, {
          variant: "body1",
          component: "div",
          align: "center",
          mb: 2,
          children: formatMessage({ id: "message.enter_verification_code" }, { phone: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(PhoneNumber_default, {
            phoneCode: "+48",
            phoneNo
          }) })
        }),
        /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material17.Box, {
          component: "form",
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material17.Grid, {
              container: true,
              justifyContent: "center",
              children: code.map((digit, idx) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material17.TextField, {
                id: `smsCode-${idx}`,
                value: digit,
                onChange: (e) => handleChange(e, idx),
                onKeyDown: (e) => handleKeyDown(e, idx),
                variant: "outlined",
                type: "number",
                inputProps: {
                  maxLength: 1,
                  style: { textAlign: "center", fontSize: "2rem" }
                },
                inputRef: (el) => inputsRef.current[idx] = el,
                sx: commonInputStyle
              }, idx))
            }),
            errorMsg && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material17.FormHelperText, {
              sx: { mb: 0.8, textAlign: "center" },
              error: true,
              children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material17.Typography, {
                variant: "caption",
                children: errorMsg
              })
            }),
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Button_default, {
              id: "smsVerificationSubmitCode-button",
              disabled: code.indexOf("") !== -1,
              onClick: handleSendCode,
              sx: { minWidth: "50%", mt: 2 },
              loading,
              children: formatMessage({ id: btnTitle })
            }),
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(CustomLink_default, {
              id: "smsVerificationResendCode-button",
              title: formatMessage({ id: "message.didnt_get_sms" }),
              btnTitle: formatMessage({ id: "button.resend_code" }),
              disabled: loading,
              fnCallback: onResendCode,
              sx: { mt: 3, mb: 1 }
            }),
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(CustomLink_default, {
              id: "smsVerificationChangePhoneNo-button",
              title: formatMessage({ id: "message.is_phone_incorrect" }),
              btnTitle: formatMessage({ id: "button.change_phone" }),
              disabled: loading,
              fnCallback: onChangePhoneNo
            })
          ]
        })
      ]
    })
  });
};
var CodeVerification_default = CodeVerification;

// src/molecules/EditButton/index.tsx
var import_react11 = require("react");
var import_react_intl2 = require("react-intl");

// src/assets/icons/CloseSmall.svg
var React6 = __toESM(require("react"));
var import_jsx_runtime = require("react/jsx-runtime");
var SvgCloseSmall = (props) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  ...props,
  children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("path", {
    fill: "none",
    stroke: "#1D1D1F",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: 2.5,
    d: "M16,5 L16,28 M5,16 L28,16",
    transform: "rotate(45 19 9)"
  })
});
var CloseSmall_default = SvgCloseSmall;

// src/assets/icons/Pen.svg
var React7 = __toESM(require("react"));
var import_jsx_runtime = require("react/jsx-runtime");
var SvgPen = (props) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)("svg", {
  width: 18,
  height: 18,
  viewBox: "0 0 18 18",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props,
  children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("path", {
    d: "M12.728 6.68608L11.314 5.27208L2 14.5861V16.0001H3.414L12.728 6.68608ZM14.142 5.27208L15.556 3.85808L14.142 2.44408L12.728 3.85808L14.142 5.27208ZM4.242 18.0001H0V13.7571L13.435 0.322083C13.6225 0.134612 13.8768 0.0292969 14.142 0.0292969C14.4072 0.0292969 14.6615 0.134612 14.849 0.322083L17.678 3.15108C17.8655 3.33861 17.9708 3.59292 17.9708 3.85808C17.9708 4.12325 17.8655 4.37756 17.678 4.56508L4.242 18.0001Z",
    fill: "#F30464"
  })
});
var Pen_default = SvgPen;

// src/molecules/EditButton/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var EditButton = (0, import_react11.memo)(
  ({
    isEditClicked,
    rounded,
    variant = "text",
    editText = "button.edit",
    cancelText = "button.restore",
    onClick,
    ...otherProps
  }) => {
    const { formatMessage } = (0, import_react_intl2.useIntl)();
    return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Button_default, {
      variant,
      size: "small",
      onClick,
      color: isEditClicked ? "inherit" : "primary",
      startIcon: isEditClicked ? /* @__PURE__ */ (0, import_jsx_runtime.jsx)(CloseSmall_default, {}) : /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Pen_default, {}),
      sx: [
        {
          bgcolor: variant === "outlined" && !isEditClicked ? "grey.100" : "inherit",
          "& svg": { width: "1.6rem", height: "auto" }
        },
        rounded ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, py: 0 } : {}
      ],
      ...otherProps,
      children: isEditClicked ? formatMessage({ id: cancelText, defaultMessage: "Przywr\xF3\u0107" }) : formatMessage({ id: editText, defaultMessage: "Edytuj" })
    });
  }
);
var EditButton_default = EditButton;

// src/molecules/FieldWithButton/index.tsx
var import_react19 = require("react");
var import_material23 = require("@mui/material");
var import_Box6 = __toESM(require("@mui/material/Box"));
var import_Typography3 = __toESM(require("@mui/material/Typography"));

// src/molecules/fields/ControlledCheckbox/index.tsx
var import_react15 = require("react");
var import_react_hook_form = require("react-hook-form");
var import_material22 = require("@mui/material");

// src/molecules/accordions/CustomAccordion/index.tsx
var import_react12 = require("react");
var import_react_intl3 = require("react-intl");
var import_material19 = require("@mui/material");
var import_Box4 = __toESM(require("@mui/material/Box"));

// src/molecules/accordions/styles.tsx
var import_material18 = require("@mui/material");
var headerStyle2 = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
};
var componentStyle = { position: "relative" };
var formControlLabelStyle = {
  alignItems: "flex-start",
  width: "100%",
  m: 0
};
var radioStyle = {
  color: "grey.300",
  "&.Mui-checked": {
    color: "text.primary"
  },
  mr: { xs: 0, md: 1.5 }
};
var StyledRadioPaper = (0, import_material18.styled)(import_material18.Paper, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "disabled"
})(({ theme, active, disabled }) => ({
  padding: ".8rem 2.4rem .8rem 1.5rem",
  marginBottom: "0.8rem",
  backgroundColor: disabled ? theme.palette.grey[100] : theme.palette.background.paper,
  border: `.1rem solid ${active ? theme.palette.text.primary : theme.palette.grey[200]}`,
  "&:hover": {
    borderColor: active ? theme.palette.text.primary : disabled ? theme.palette.grey[200] : theme.palette.grey[300]
  },
  [theme.breakpoints.down("md")]: {
    padding: ".8rem 1.2rem 0.8rem 0"
  }
}));

// src/molecules/accordions/CustomAccordion/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var CustomAccordion = (0, import_react12.memo)(function CustomAccordionGroup({
  title,
  subtitle,
  subtitleVariant = "subtitle2",
  moreText = "button.more",
  moreTextId,
  lessText = "button.less",
  rightText,
  children,
  disabled,
  sx = [],
  contentSx = []
}) {
  const { formatMessage } = (0, import_react_intl3.useIntl)();
  const [active, setActive] = (0, import_react12.useState)(false);
  const handleToggle = (0, import_react12.useCallback)(
    (e) => {
      e.preventDefault();
      setActive(!active);
    },
    [active]
  );
  const expandLink = (text) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material19.Link, {
    component: "button",
    color: "primary",
    onClick: handleToggle,
    sx: { ml: 0.3 },
    id: moreTextId,
    children: formatMessage({ id: text })
  });
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_Box4.default, {
    sx: [{ width: "100%" }, ...Array.isArray(sx) ? sx : [sx]],
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_Box4.default, {
        sx: [headerStyle2],
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_Box4.default, {
            sx: { cursor: disabled ? "default" : "pointer" },
            children: [
              title && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material19.Typography, {
                variant: "body1",
                component: typeof title === "string" ? "p" : "div",
                children: title
              }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material19.Typography, {
                variant: subtitleVariant,
                component: "div",
                color: "text.secondary",
                children: [
                  subtitle,
                  /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material19.Typography, {
                    variant: "subtitle2",
                    component: "span",
                    className: "rightText",
                    children: children && !active && expandLink(moreText)
                  })
                ]
              })
            ]
          }),
          rightText && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material19.Typography, {
            variant: "h6",
            sx: { whiteSpace: "nowrap", ml: { xs: 0.8, md: 1.6 } },
            children: rightText
          })
        ]
      }),
      children && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material19.Collapse, {
        in: active,
        children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material19.Typography, {
          variant: "subtitle2",
          component: "div",
          mt: 0.4,
          color: "grey.400",
          sx: [...Array.isArray(contentSx) ? contentSx : [contentSx]],
          children: [
            children,
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material19.Link, {
              component: "button",
              color: "primary",
              onClick: handleToggle,
              sx: { ml: 0.3 },
              children: formatMessage({ id: lessText })
            })
          ]
        })
      })
    ]
  });
});

// src/molecules/accordions/MainAccordion/index.tsx
var import_react13 = require("react");
var import_material20 = require("@mui/material");
var import_Box5 = __toESM(require("@mui/material/Box"));

// src/assets/icons/ChevronRight.svg
var React8 = __toESM(require("react"));
var import_jsx_runtime = require("react/jsx-runtime");
var SvgChevronRight = (props) => /* @__PURE__ */ (0, import_jsx_runtime.jsxs)("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props,
  children: [
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("g", {
      id: "Frame",
      clipPath: "url(#clip0_56_1690)",
      children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("path", {
        id: "Vector",
        d: "M12.172 11.9998L9.34302 9.17184L10.757 7.75684L15 11.9998L10.757 16.2428L9.34302 14.8278L12.172 11.9998Z",
        fill: "currentColor"
      })
    }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("defs", {
      children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("clipPath", {
        id: "clip0_56_1690",
        children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("rect", {
          width: 24,
          height: 24,
          fill: "white"
        })
      })
    })
  ]
});
var ChevronRight_default = SvgChevronRight;

// src/molecules/accordions/MainAccordion/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var MainAccordion = (0, import_react13.memo)(function MainAccordion2({
  title,
  titleColor = "text.primary",
  id,
  children,
  handleToggleCallback,
  sx = []
}) {
  const [, startTransition] = (0, import_react13.useTransition)();
  const [active, setActive] = (0, import_react13.useState)(false);
  const hiddenClickableEl = (0, import_react13.useRef)(null);
  const handleToggle = (0, import_react13.useCallback)(() => {
    startTransition(() => {
      setActive(!active);
    });
  }, [active]);
  (0, import_react13.useEffect)(() => {
    if (typeof handleToggleCallback === "function") {
      handleToggleCallback(active);
    }
    if (active && (hiddenClickableEl == null ? void 0 : hiddenClickableEl.current)) {
      hiddenClickableEl.current.click();
    }
  }, [active]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_Box5.default, {
    sx: [componentStyle, ...Array.isArray(sx) ? sx : [sx]],
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)("input", {
        hidden: true,
        id,
        ref: hiddenClickableEl
      }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_Box5.default, {
        onClick: handleToggle,
        sx: [headerStyle2, { cursor: "pointer", alignItems: typeof title === "string" ? "flex-start" : "center" }],
        color: active && typeof title === "string" ? "primary.main" : titleColor,
        "data-testid": "main-accordion-title",
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material20.Typography, {
            variant: "body2",
            component: "div",
            children: title
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Box5.default, {
            sx: [{ display: "flex", "& svg": { transition: "0.3s" } }, active ? { "& svg": { transform: "rotate(90deg)" } } : {}],
            children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(ChevronRight_default, {
              fill: "inherit"
            })
          })
        ]
      }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material20.Collapse, {
        in: active,
        "data-testid": "main-accordion-content",
        "aria-hidden": !active,
        children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Box5.default, {
          children
        })
      })
    ]
  });
});

// src/molecules/accordions/RadioAccordionGroup/index.tsx
var import_react14 = require("react");
var import_material21 = require("@mui/material");
var import_jsx_runtime = require("react/jsx-runtime");
var RadioAccordionGroup = (0, import_react14.memo)(function RadioAccordionGroup2({
  items,
  disabled = false,
  selectedValue,
  onChange,
  sx,
  accordionSx,
  ...otherProps
}) {
  const handleChange = (0, import_react14.useCallback)(
    (event) => {
      onChange(event.target.value);
    },
    []
  );
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material21.FormControl, {
    sx: { width: "100%" },
    disabled,
    children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material21.RadioGroup, {
      "aria-labelledby": "radio-buttons-group-label",
      name: "radio-buttons-group",
      value: selectedValue,
      onChange: handleChange,
      sx: [...Array.isArray(sx) ? sx : [sx]],
      children: items.map((el) => {
        var _a3;
        return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(StyledRadioPaper, {
          active: selectedValue === el.key,
          disabled,
          children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material21.FormControlLabel, {
            value: el.key,
            control: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material21.Radio, {
              color: "default",
              id: el.id,
              sx: radioStyle
            }),
            disableTypography: true,
            sx: formControlLabelStyle,
            label: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(CustomAccordion, {
              title: el.title,
              subtitle: el.subtitle,
              rightText: el.rightText,
              sx: accordionSx,
              moreTextId: `${el.id}-expand`,
              disabled,
              ...otherProps,
              children: el.content
            })
          })
        }, (_a3 = el.title) == null ? void 0 : _a3.toString());
      })
    })
  });
});

// src/molecules/fields/ControlledCheckbox/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var ControlledCheckbox = ({
  fieldName,
  type = "checkbox",
  required,
  control,
  label,
  labelExpanded,
  iconSize = "1.6rem",
  disabled,
  onChangeOverride
}) => {
  const renderField = (0, import_react15.useCallback)(
    ({ field: { onChange, value, name, ref }, fieldState: { error } }) => {
      const checkboxEl = /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material22.FormControlLabel, {
        disabled,
        control: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material22.Box, {
          sx: { position: "absolute", top: 0, left: `calc(-${iconSize} - .8rem)`, display: "flex" },
          children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Checkbox_default, {
            checked: value,
            disabled,
            name,
            onChange: typeof onChangeOverride === "function" ? (e) => onChangeOverride(e, onChange) : onChange,
            inputRef: ref,
            size: iconSize,
            sx: { ml: 0, mt: type === "checkbox-accordion" ? 0 : 0.2 },
            inputProps: {
              id: name
            }
          })
        }),
        label: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material22.Typography, {
          variant: "body2",
          component: "span",
          color: "text.secondary",
          children: [
            label,
            required && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(RequiredSign_default, {})
          ]
        }),
        sx: { display: "inline", m: 0, position: "relative", lineHeight: "normal" }
      });
      const errorEl = error && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material22.FormHelperText, {
        sx: { mt: 0.4, ml: 0 },
        error: true,
        children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material22.Typography, {
          variant: "caption",
          children: error == null ? void 0 : error.message
        })
      });
      if (type === "checkbox-accordion") {
        return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_jsx_runtime.Fragment, {
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(CustomAccordion, {
              subtitle: checkboxEl,
              subtitleVariant: "body2",
              moreTextId: `${name}-expand`,
              sx: disabled ? { "& .MuiTypography-body2": { cursor: "default", color: "grey.400" } } : {},
              children: labelExpanded
            }),
            errorEl
          ]
        });
      }
      return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_jsx_runtime.Fragment, {
        children: [
          checkboxEl,
          errorEl
        ]
      });
    },
    [disabled, onChangeOverride, type, iconSize, label, required, labelExpanded]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material22.FormControl, {
    component: "fieldset",
    sx: { ml: `calc(${iconSize} + .8rem)` },
    children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_hook_form.Controller, {
      name: fieldName,
      control,
      defaultValue: false,
      render: renderField
    })
  });
};

// src/molecules/fields/ControlledDatePicker/index.tsx
var import_react16 = require("react");
var import_react_hook_form2 = require("react-hook-form");
var import_jsx_runtime = require("react/jsx-runtime");
var ControlledDatePicker = ({
  fieldName,
  control,
  helperText,
  onPressEnter,
  onChangeOverride,
  ...otherProps
}) => {
  const renderField = (0, import_react16.useCallback)(
    ({ field, fieldState }) => {
      var _a3;
      return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(DatePicker, {
        name: field.name,
        value: field.value,
        onChange: typeof onChangeOverride === "function" ? (value) => onChangeOverride(value, field.onChange) : field.onChange,
        error: !!fieldState.error,
        helperText,
        errorHelperText: (_a3 = fieldState.error) == null ? void 0 : _a3.message,
        onPressEnter,
        inputRef: field.ref,
        ...otherProps
      });
    },
    [otherProps, helperText, onChangeOverride, onPressEnter]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_hook_form2.Controller, {
    name: fieldName,
    control,
    render: renderField
  });
};

// src/molecules/fields/ControlledSelect/index.tsx
var import_react17 = require("react");
var import_react_hook_form3 = require("react-hook-form");
var import_jsx_runtime = require("react/jsx-runtime");
var ControlledSelect = ({
  fieldName,
  control,
  placeholder = "",
  label,
  options,
  inputProps,
  helperText,
  disabled
}) => {
  const renderField = (0, import_react17.useCallback)(
    ({ field, fieldState }) => {
      var _a3;
      return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Select, {
        name: field.name,
        label,
        options,
        value: field.value,
        placeholder,
        onChange: field.onChange,
        error: !!fieldState.error,
        helperText,
        errorHelperText: (_a3 = fieldState.error) == null ? void 0 : _a3.message,
        inputProps,
        disabled,
        inputRef: field.ref
      });
    },
    [label, options, placeholder, helperText, inputProps, disabled]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_hook_form3.Controller, {
    name: fieldName,
    control,
    render: renderField
  });
};

// src/molecules/fields/ControlledTextField/index.tsx
var import_react18 = require("react");
var import_react_hook_form4 = require("react-hook-form");
var import_jsx_runtime = require("react/jsx-runtime");
var ControlledTextField = ({
  fieldName,
  control,
  placeholder = "",
  type = "text",
  label,
  inputProps,
  helperText,
  onPressEnter,
  disabled,
  helperIconLabelText,
  ...otherProps
}) => {
  const renderField = (0, import_react18.useCallback)(
    ({ field, fieldState }) => {
      var _a3;
      return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(TextField, {
        name: field.name,
        label,
        value: field.value,
        placeholder,
        onChange: field.onChange,
        onBlur: field.onBlur,
        error: !!fieldState.error,
        helperText,
        errorHelperText: (_a3 = fieldState.error) == null ? void 0 : _a3.message,
        helperIconLabelText,
        type,
        inputProps,
        onPressEnter,
        disabled,
        inputRef: field.ref,
        ...otherProps
      });
    },
    [label, placeholder, helperText, type, inputProps, disabled, helperIconLabelText]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_hook_form4.Controller, {
    name: fieldName,
    control,
    render: renderField
  });
};

// src/molecules/FieldWithButton/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var FieldWithButton = (0, import_react19.memo)(
  ({
    label,
    name = "fieldWithButton",
    placeholder = "",
    onSubmit,
    successInfo,
    buttonText,
    inputProps,
    sx,
    disabled,
    control
  }) => {
    const isControlled = !!control;
    const [inputValue, setInputValue] = (0, import_react19.useState)("");
    const handleChange = (e) => {
      setInputValue(e.target.value);
    };
    const handleSubmit = (0, import_react19.useCallback)(() => {
      if (isControlled)
        onSubmit();
      else if (inputValue)
        onSubmit(inputValue);
    }, [inputValue]);
    const textfieldInputProps = {
      ...inputProps,
      sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
    };
    const textfieldSx = (inputProps == null ? void 0 : inputProps.fullWidth) ? { width: "100%" } : [];
    return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_Box6.default, {
      sx: [{}, ...Array.isArray(sx) ? sx : [sx]],
      children: [
        label && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(InputLabel_default, {
          id: name,
          sx: { color: "text.primary" },
          children: label
        }),
        /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_Box6.default, {
          display: "flex",
          alignItems: isControlled ? "baseline" : "center",
          children: [
            isControlled ? /* @__PURE__ */ (0, import_jsx_runtime.jsx)(ControlledTextField, {
              fieldName: name,
              placeholder,
              inputProps: textfieldInputProps,
              disabled,
              control,
              sx: textfieldSx
            }) : /* @__PURE__ */ (0, import_jsx_runtime.jsx)(TextField, {
              name,
              value: inputValue,
              placeholder,
              onChange: handleChange,
              inputProps: textfieldInputProps,
              disabled,
              onPressEnter: handleSubmit,
              sx: textfieldSx
            }),
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Button_default, {
              size: "small",
              sx: {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                py: 0.9
              },
              disabled,
              onClick: handleSubmit,
              id: `${name}-button`,
              children: buttonText
            })
          ]
        }),
        successInfo && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material23.FormHelperText, {
          sx: { mt: 0.8 },
          error: (successInfo == null ? void 0 : successInfo.type) === "error",
          children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Typography3.default, {
            variant: "caption",
            color: `${successInfo == null ? void 0 : successInfo.type}.main`,
            sx: { whiteSpace: "pre-line" },
            children: successInfo == null ? void 0 : successInfo.message
          })
        })
      ]
    });
  }
);
var FieldWithButton_default = FieldWithButton;

// src/molecules/Stepper/index.tsx
var import_react20 = require("react");
var import_Box7 = __toESM(require("@mui/material/Box"));
var import_Step = __toESM(require("@mui/material/Step"));
var import_Stepper = __toESM(require("@mui/material/Stepper"));

// src/assets/icons/CheckCircle.svg
var React9 = __toESM(require("react"));
var import_jsx_runtime = require("react/jsx-runtime");
var SvgCheckCircle = (props) => /* @__PURE__ */ (0, import_jsx_runtime.jsxs)("svg", {
  width: 16,
  height: 16,
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props,
  children: [
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("g", {
      id: "Frame",
      clipPath: "url(#clip0_56_1685)",
      children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("path", {
        id: "Vector",
        d: "M7.99992 14.6668C4.31792 14.6668 1.33325 11.6822 1.33325 8.00016C1.33325 4.31816 4.31792 1.3335 7.99992 1.3335C11.6819 1.3335 14.6666 4.31816 14.6666 8.00016C14.6666 11.6822 11.6819 14.6668 7.99992 14.6668ZM7.33525 10.6668L12.0486 5.95283L11.1059 5.01016L7.33525 8.7815L5.44925 6.8955L4.50659 7.83816L7.33525 10.6668Z",
        fill: "currentColor"
      })
    }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("defs", {
      children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("clipPath", {
        id: "clip0_56_1685",
        children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("rect", {
          width: 16,
          height: 16,
          fill: "white"
        })
      })
    })
  ]
});
var CheckCircle_default = SvgCheckCircle;

// src/molecules/Stepper/styles.tsx
var import_material24 = require("@mui/material");
var containerStyle = [
  {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    WebkitOverflowScrolling: "touch"
  },
  process.env.NODE_ENV !== "development" && {
    "&::-webkit-scrollbar": {
      display: "none"
    }
  }
];
var checkIconStyle = {
  width: "1.6rem",
  height: "1.6rem",
  mr: 0.4,
  display: "flex",
  "& svg": {
    width: "100%",
    height: "100%"
  }
};
var connectorContainerStyle = {
  flex: "1 1 auto",
  display: "flex",
  justifyContent: "center",
  alignSelf: "baseline"
};
var StyledConnector = (0, import_material24.styled)(import_material24.Box, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "completed"
})(
  ({ theme, completed, active }) => theme.unstable_sx({
    display: "flex",
    color: completed || active ? "text.primary" : "text.secondary",
    mx: 1.2,
    "& svg": {
      width: "2.4rem",
      height: "2.4rem"
    },
    border: ".1rem solid",
    borderColor: "transparent",
    borderRadius: "50%",
    p: 0.3,
    my: 1.6,
    ...active && {
      color: "text.primary",
      borderColor: (theme2) => theme2.palette.grey[200]
    }
  })
);
var StyledStepLabel = (0, import_material24.styled)(import_material24.StepLabel, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "completed"
})(
  ({ theme, completed, active }) => theme.unstable_sx({
    pt: 2.4,
    pb: 2.2,
    "& .MuiStepLabel-label": {
      typography: "caption",
      display: "flex",
      alignItems: "center",
      color: completed ? "text.primary" : active ? "primary.main" : "text.secondary",
      fontWeight: active ? 700 : 500
    },
    ...active && {
      borderBottom: ".2rem solid",
      borderColor: "primary.main"
    }
  })
);

// src/molecules/Stepper/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var CustomConnector = ({ active, completed }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Box7.default, {
    sx: connectorContainerStyle,
    children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(StyledConnector, {
      completed,
      active,
      children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(ChevronRight_default, {
        fill: "inherit"
      })
    })
  });
};
var Stepper = ({ activeStep, steps }) => {
  const activeStepNo = steps.findIndex((step) => step.key === activeStep);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Box7.default, {
    sx: containerStyle,
    children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Stepper.default, {
      activeStep: activeStepNo,
      connector: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_jsx_runtime.Fragment, {}),
      sx: { flex: "0 0 auto" },
      children: steps.map((step, idx) => {
        const stepInfo = {
          active: idx === activeStepNo,
          completed: idx < activeStepNo,
          last: idx === steps.length - 1
        };
        return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_react20.Fragment, {
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Step.default, {
              sx: { px: { xs: 0, md: 0.8 } },
              children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(StyledStepLabel, {
                completed: stepInfo.completed,
                active: stepInfo.active,
                children: [
                  stepInfo.completed && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Box7.default, {
                    sx: checkIconStyle,
                    component: "span",
                    "data-testid": "checkIcon",
                    children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(CheckCircle_default, {
                      fill: "inherit"
                    })
                  }),
                  idx + 1,
                  ". ",
                  step.title
                ]
              })
            }),
            !stepInfo.last && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(CustomConnector, {
              ...stepInfo
            })
          ]
        }, step.key);
      })
    })
  });
};
var Stepper_default = Stepper;

// src/atoms/fields/TextField/styles.tsx
var phoneAdornmentStyle = {
  color: "text.secondary",
  px: 0.8,
  borderRight: "1px solid",
  borderRightColor: "grey.300"
};
var boxPhoneStyles = {
  "& .MuiInputBase-adornedStart": {
    bgcolor: "grey.100"
  }
};

// src/atoms/fields/TextField/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var TextField = (0, import_react21.memo)(
  ({
    name,
    label,
    helperText,
    value,
    type = "text",
    onChange,
    placeholder,
    error,
    errorHelperText,
    inputProps,
    onPressEnter,
    disabled,
    editableInitValue,
    helperIconLabelText,
    isNotEditable = false,
    onEditClick,
    sx = [],
    ...rest
  }) => {
    const inputDisabled = disabled || (inputProps == null ? void 0 : inputProps.disabled) || Boolean(editableInitValue && isNotEditable);
    const startAdornment = (0, import_react21.useMemo)(() => {
      if (type === "phone") {
        return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material25.InputAdornment, {
          position: "start",
          disablePointerEvents: true,
          "data-testid": `startAdornment-${type}`,
          children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material25.Box, {
            display: "flex",
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(FlagPL_default, {}),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material25.Typography, {
                variant: "body2",
                sx: phoneAdornmentStyle,
                children: "(+48)"
              })
            ]
          })
        });
      }
      return;
    }, [type]);
    const handleChange = (0, import_react21.useCallback)(
      (e) => {
        if (type === "phone") {
          const formattedPhone = formatPhoneNumberWithoutSeparator(e.target.value);
          onChange(formattedPhone);
        } else if (type === "iban") {
          const formattedInput = formatIbanWithoutSpaces(e.target.value);
          onChange(formattedInput);
        } else
          onChange(e);
      },
      [type]
    );
    const handleCopy = (0, import_react21.useCallback)(
      (e) => {
        const currentSelection = window.getSelection();
        if (currentSelection) {
          if (type === "phone") {
            const valueWithoutSeparators = formatPhoneNumberWithoutSeparator(currentSelection.toString());
            e.clipboardData.setData("text/plain", valueWithoutSeparators);
          } else if (type === "iban") {
            const valueWithoutSeparators = formatIbanWithoutSpaces(currentSelection.toString());
            e.clipboardData.setData("text/plain", valueWithoutSeparators);
          } else
            e.clipboardData.setData("text/plain", currentSelection.toString());
          e.preventDefault();
        }
      },
      [type]
    );
    const formattedValue = () => {
      switch (type) {
        case "phone":
          return formatPhoneNumber(value);
        case "iban":
          return formatIban(value);
        default:
          return value;
      }
    };
    const onEdit = () => {
      if (typeof onEditClick === "function") {
        onEditClick(name);
        if (isNotEditable) {
          onChange("");
        } else {
          if (editableInitValue)
            onChange(editableInitValue);
        }
      }
    };
    return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material25.Box, {
      sx: [inputDisabled && type === "phone" ? boxPhoneStyles : {}, ...Array.isArray(sx) ? sx : [sx]],
      children: [
        label && /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(InputLabel_default, {
          id: name,
          sx: { color: inputDisabled ? "text.secondary" : "text.primary", display: "flex", alignItems: "center" },
          children: [
            label,
            helperIconLabelText && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material25.Tooltip, {
              title: helperIconLabelText,
              placement: "top",
              children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material25.Box, {
                sx: { ml: 0.8, mb: 0.1, display: "flex", "& svg": { width: "1.5rem", height: "auto" } },
                children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(InfoCircle_default, {})
              })
            })
          ]
        }),
        helperText && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material25.FormHelperText, {
          sx: { mb: 0.8 },
          children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material25.Typography, {
            variant: "caption",
            children: helperText
          })
        }),
        /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material25.Box, {
          display: "inline-flex",
          sx: [{ width: (inputProps == null ? void 0 : inputProps.fullWidth) ? "100%" : "auto" }],
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material25.OutlinedInput, {
              name,
              id: name,
              value: isNotEditable && editableInitValue ? editableInitValue : formattedValue(),
              placeholder: placeholder || "",
              onChange: handleChange,
              error,
              type,
              startAdornment,
              disabled: inputDisabled,
              ...rest,
              ...inputProps,
              inputProps: {
                onCopy: handleCopy
              },
              onKeyDown: (e) => {
                if (e.key === "Enter" && typeof onPressEnter === "function") {
                  e.preventDefault();
                  onPressEnter();
                }
              },
              sx: !disabled && editableInitValue ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {}
            }),
            !disabled && editableInitValue && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(EditButton_default, {
              isEditClicked: !isNotEditable,
              onClick: onEdit,
              rounded: true,
              variant: "outlined"
            })
          ]
        }),
        error && errorHelperText && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material25.FormHelperText, {
          sx: { mt: 0.8 },
          error: true,
          children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material25.Typography, {
            variant: "caption",
            sx: { whiteSpace: "pre-line" },
            children: errorHelperText
          })
        })
      ]
    });
  }
);

// src/atoms/fields/DatePicker/index.tsx
var import_react22 = require("react");
var import_material26 = require("@mui/material");
var import_x_date_pickers = require("@mui/x-date-pickers");
var import_AdapterDayjs = require("@mui/x-date-pickers/AdapterDayjs");
var import_locales = require("@mui/x-date-pickers/locales");
var import_dayjs = __toESM(require("dayjs"));

// src/assets/icons/Calendar.svg
var React10 = __toESM(require("react"));
var import_jsx_runtime = require("react/jsx-runtime");
var SvgCalendar = (props) => /* @__PURE__ */ (0, import_jsx_runtime.jsxs)("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props,
  children: [
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("g", {
      id: "icon/calendar",
      clipPath: "url(#clip0_56_1900)",
      children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("path", {
        id: "Vector",
        d: "M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM15 5H9V7H7V5H4V9H20V5H17V7H15V5ZM20 11H4V19H20V11Z",
        fill: "currentColor"
      })
    }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("defs", {
      children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("clipPath", {
        id: "clip0_56_1900",
        children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("rect", {
          width: 24,
          height: 24,
          fill: "white"
        })
      })
    })
  ]
});
var Calendar_default = SvgCalendar;

// src/atoms/fields/DatePicker/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var plLocale = import_locales.plPL.components.MuiLocalizationProvider.defaultProps.localeText;
var enLocale = import_locales.enUS.components.MuiLocalizationProvider.defaultProps.localeText;
var setLangLocale = (lang) => {
  switch (lang) {
    case "pl":
      return plLocale;
    case "en":
      return enLocale;
    default:
      return plLocale;
  }
};
var DatePicker = (0, import_react22.memo)(
  ({
    label,
    onChange,
    value,
    name,
    helperText,
    error,
    errorHelperText,
    format = "DD-MM-YYYY",
    datePickerProps,
    datePickerSlotProps = {},
    onPressEnter,
    disabled,
    editableInitValue,
    ...rest
  }) => {
    var _a3, _b;
    const lang = (_a3 = localStorage.getItem("lang")) != null ? _a3 : "pl";
    const textFieldSlots = (_b = datePickerSlotProps == null ? void 0 : datePickerSlotProps.textField) != null ? _b : {};
    const inputDisabled = disabled || (textFieldSlots == null ? void 0 : textFieldSlots.disabled) || Boolean(editableInitValue);
    return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material26.Box, {
      children: [
        label && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(InputLabel_default, {
          id: name,
          sx: { color: inputDisabled ? "text.secondary" : "text.primary" },
          children: label
        }),
        helperText && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material26.FormHelperText, {
          sx: { mb: 0.8 },
          children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material26.Typography, {
            variant: "caption",
            children: helperText
          })
        }),
        editableInitValue ? /* @__PURE__ */ (0, import_jsx_runtime.jsx)(TextField, {
          value: String(editableInitValue),
          disabled: true,
          name,
          onChange: () => {
          },
          inputProps: {
            ...textFieldSlots,
            endAdornment: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material26.Box, {
              sx: { display: "flex", mr: -0.4 },
              children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Calendar_default, {
                width: 24,
                height: 24
              })
            })
          }
        }) : /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material26.Box, {
          display: "inline-flex",
          sx: [{ width: (textFieldSlots == null ? void 0 : textFieldSlots.fullWidth) ? "100%" : "auto" }],
          children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_x_date_pickers.LocalizationProvider, {
            dateAdapter: import_AdapterDayjs.AdapterDayjs,
            adapterLocale: lang,
            localeText: setLangLocale(lang),
            children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_x_date_pickers.DatePicker, {
              value: value ? (0, import_dayjs.default)(value) : null,
              onChange,
              format,
              slotProps: {
                ...datePickerSlotProps,
                openPickerButton: {
                  id: `${name}-openDatepicker`
                },
                openPickerIcon: {
                  id: `${name}-openDatepicker`
                },
                textField: {
                  name,
                  id: name,
                  error,
                  onKeyDown: (e) => {
                    if (e.key === "Enter" && typeof onPressEnter === "function") {
                      e.preventDefault();
                      onPressEnter();
                    }
                  },
                  ...textFieldSlots
                }
              },
              disabled: inputDisabled,
              slots: { openPickerIcon: Calendar_default },
              sx: editableInitValue ? { "& .MuiInputBase-root": { borderTopRightRadius: 0, borderBottomRightRadius: 0 } } : {},
              ...rest,
              ...datePickerProps
            })
          })
        }),
        error && errorHelperText && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material26.FormHelperText, {
          sx: { mt: 0.8 },
          error: true,
          children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material26.Typography, {
            variant: "caption",
            sx: { whiteSpace: "pre-line" },
            children: errorHelperText
          })
        })
      ]
    });
  }
);

// src/atoms/fields/Select/index.tsx
var import_react23 = require("react");
var import_material27 = require("@mui/material");

// src/assets/icons/ArrowDropDown.svg
var React11 = __toESM(require("react"));
var import_jsx_runtime = require("react/jsx-runtime");
var SvgArrowDropDown = (props) => /* @__PURE__ */ (0, import_jsx_runtime.jsxs)("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props,
  children: [
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("g", {
      id: "Frame",
      clipPath: "url(#clip0_583_2031)",
      children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("path", {
        id: "Vector",
        d: "M12 12.1718L14.828 9.34277L16.243 10.7568L12 14.9998L7.75704 10.7568L9.17204 9.34277L12 12.1718Z",
        fill: "currentColor"
      })
    }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("defs", {
      children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("clipPath", {
        id: "clip0_583_2031",
        children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("rect", {
          width: 24,
          height: 24,
          fill: "white",
          transform: "translate(24) rotate(90)"
        })
      })
    })
  ]
});
var ArrowDropDown_default = SvgArrowDropDown;

// src/atoms/fields/Select/styles.tsx
var commonSelectStyle = {
  minWidth: 178,
  "& .MuiSelect-icon": {
    top: "calc(50% - 12px)",
    transition: "0.3s"
  }
};

// src/atoms/fields/Select/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var Select = (0, import_react23.memo)(
  ({
    name,
    label,
    helperText,
    value,
    options,
    onChange,
    placeholder,
    error,
    errorHelperText,
    menuItemSx = [],
    inputProps,
    disabled,
    ...rest
  }) => {
    const inputDisabled = disabled || (inputProps == null ? void 0 : inputProps.disabled);
    const hiddenClickableEl = (0, import_react23.useRef)(null);
    const sxStyle = (inputProps == null ? void 0 : inputProps.sx) ? Array.isArray(inputProps == null ? void 0 : inputProps.sx) ? inputProps == null ? void 0 : inputProps.sx : [inputProps == null ? void 0 : inputProps.sx] : [];
    return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material27.Box, {
      children: [
        label && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(InputLabel_default, {
          id: `${name}-input`,
          sx: { color: inputDisabled ? "text.secondary" : "text.primary" },
          children: label
        }),
        helperText && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material27.FormHelperText, {
          sx: { mb: 0.8 },
          children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material27.Typography, {
            variant: "caption",
            children: helperText
          })
        }),
        /* @__PURE__ */ (0, import_jsx_runtime.jsx)("input", {
          hidden: true,
          id: `${name}`,
          ref: hiddenClickableEl
        }),
        /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_material27.Select, {
          name,
          id: `${name}-select`,
          value: value || "",
          displayEmpty: !!placeholder,
          placeholder: placeholder || "",
          onChange,
          disabled: inputDisabled,
          error,
          renderValue: (selected) => {
            if (!selected && placeholder) {
              return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material27.Typography, {
                color: "text.secondary",
                variant: "inherit",
                component: "span",
                children: placeholder
              });
            }
            return selected;
          },
          onOpen: () => {
            if (hiddenClickableEl == null ? void 0 : hiddenClickableEl.current) {
              hiddenClickableEl.current.click();
            }
          },
          SelectDisplayProps: {
            "data-testid": "select-element"
          },
          IconComponent: ArrowDropDown_default,
          ...rest,
          ...inputProps,
          inputProps: { id: `${name}-input` },
          sx: [commonSelectStyle, ...sxStyle],
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material27.MenuItem, {
              disabled: true,
              value: "",
              sx: { display: "none" },
              children: placeholder
            }),
            options == null ? void 0 : options.map((o, i) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material27.MenuItem, {
              value: o.value,
              sx: menuItemSx,
              disabled: o.disabled,
              children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material27.Typography, {
                variant: "body2",
                children: o.label ? o.label : o.value
              })
            }, `${o.value}-${i}`))
          ]
        }),
        error && errorHelperText && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material27.FormHelperText, {
          sx: { mt: 0.8 },
          error: true,
          children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_material27.Typography, {
            variant: "caption",
            sx: { whiteSpace: "pre-line" },
            children: errorHelperText
          })
        })
      ]
    });
  }
);

// src/atoms/infoBars/InfoBar/index.tsx
var import_Typography4 = __toESM(require("@mui/material/Typography"));

// src/atoms/infoBars/InfoBar/styles.tsx
var import_material28 = require("@mui/material");
var import_Box8 = __toESM(require("@mui/material/Box"));
var getStyles = (variant, color) => {
  switch (true) {
    case ((variant === "outlined" || variant === "contained") && color === "default"):
      return { bgcolor: "grey.100", color: "grey.500" };
    case (variant === "outlined" && color === "warning"):
      return { bgcolor: "#FFF3E8", color: "warning.main" };
    case (variant === "outlined" && color === "success"):
      return { bgcolor: "#ebfef1", color: "success.main" };
    case ((variant === "outlined" || variant === "contained") && color === "transparent"):
      return { color: "text.primary" };
    case (variant === "contained" && color === "warning"):
      return {
        bgcolor: "warning.main",
        color: "common.white",
        "& .MuiTypography-subtitle2": { fontWeight: 700 }
      };
    case (variant === "contained" && color === "success"):
      return {
        bgcolor: "success.main",
        color: "common.white",
        "& .MuiTypography-subtitle2": { fontWeight: 700 }
      };
    default:
      return { bgcolor: "grey.100", color: "grey.500" };
  }
};
var StyledBox = (0, import_material28.styled)(import_Box8.default, {
  shouldForwardProp: (prop) => prop !== "variant" && prop !== "color" && prop !== "rounded" && prop !== "align" && prop !== "iconSize"
})(
  ({ theme, variant, color, rounded, align, iconSize }) => theme.unstable_sx({
    display: "flex",
    alignItems: "center",
    py: 0.8,
    px: color === "transparent" ? 0 : 1,
    justifyContent: align,
    borderRadius: rounded ? 1 : 0,
    "& svg": {
      width: "auto",
      height: iconSize,
      mr: 0.8,
      flexShrink: 0
    },
    ...getStyles(variant, color)
  })
);

// src/atoms/infoBars/InfoBar/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var InfoBar = ({
  title,
  variant = "outlined",
  color = "default",
  rounded = true,
  icon = InfoCircle_default,
  iconSize = 2,
  align = "center",
  sx = [],
  titleSx = []
}) => {
  const Icon = icon;
  const size = typeof iconSize === "number" ? `${iconSize}rem` : iconSize;
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(StyledBox, {
    variant,
    color,
    rounded,
    align,
    iconSize: size,
    sx: [{}, ...Array.isArray(sx) ? sx : [sx]],
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Icon, {
        fill: "inherit"
      }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Typography4.default, {
        variant: "subtitle2",
        component: typeof title === "string" ? "h6" : "div",
        color: "inherit",
        sx: [{ whiteSpace: "pre-line" }, ...Array.isArray(titleSx) ? titleSx : [titleSx]],
        children: title
      })
    ]
  });
};

// src/atoms/loaders/LinearProgress/index.tsx
var import_react24 = require("react");
var import_Box9 = __toESM(require("@mui/material/Box"));

// src/atoms/loaders/LinearProgress/styles.tsx
var import_LinearProgress = __toESM(require("@mui/material/LinearProgress"));
var import_styles12 = require("@mui/material/styles");
var StyledLinearProgress = (0, import_styles12.styled)(import_LinearProgress.default)(({ theme }) => ({
  height: 8,
  borderRadius: 100,
  [`&.${import_LinearProgress.linearProgressClasses.root}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 100 : 700]
  },
  ["& .MuiLinearProgress-bar"]: {
    borderRadius: 100
  }
}));

// src/atoms/loaders/LinearProgress/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var LinearProgress = ({ color = "primary", timerValue, timeIsOver, sx = [] }) => {
  const [currentValue, setCurrentValue] = (0, import_react24.useState)(0);
  (0, import_react24.useEffect)(() => {
    const targetValue = 100;
    const steps = Math.ceil(timerValue / 2e3);
    const duration = timerValue;
    const updateInterval = duration / steps;
    let smoothIncrementInterval;
    const smoothlyIncreaseToTarget = () => {
      let currentStep = 0;
      smoothIncrementInterval = setInterval(() => {
        currentStep += 1;
        const progress = currentStep / steps;
        const randomIncrement = Math.random() * 5 + 1;
        setCurrentValue((prevValue) => Math.min(prevValue + randomIncrement, targetValue * progress));
        if (progress >= 1) {
          clearInterval(smoothIncrementInterval);
        }
      }, updateInterval);
      setTimeout(() => {
        clearInterval(smoothIncrementInterval);
      }, duration);
    };
    if (timeIsOver) {
      setCurrentValue(100);
      clearInterval(smoothIncrementInterval);
    } else {
      smoothlyIncreaseToTarget();
    }
    return () => {
      if (smoothIncrementInterval) {
        clearInterval(smoothIncrementInterval);
      }
    };
  }, [timerValue, timeIsOver]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Box9.default, {
    sx: [{ width: "100%" }, ...Array.isArray(sx) ? sx : [sx]],
    children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(StyledLinearProgress, {
      variant: "determinate",
      value: currentValue,
      color
    })
  });
};

// src/atoms/loaders/Spinner/index.tsx
var import_Box10 = __toESM(require("@mui/material/Box"));

// src/assets/icons/Spinner.svg
var React12 = __toESM(require("react"));
var import_jsx_runtime = require("react/jsx-runtime");
var SvgSpinner = (props) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)("svg", {
  width: 100,
  height: 100,
  viewBox: "0 0 100 100",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props,
  children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("g", {
    id: "Frame",
    children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("path", {
      id: "Vector",
      d: "M50 8.3335C52.3012 8.3335 54.1667 10.199 54.1667 12.5002V25.0002C54.1667 27.3013 52.3012 29.1668 50 29.1668C47.6987 29.1668 45.8333 27.3013 45.8333 25.0002V12.5002C45.8333 10.199 47.6987 8.3335 50 8.3335ZM50 70.8335C52.3012 70.8335 54.1667 72.6989 54.1667 75.0002V87.5002C54.1667 89.8014 52.3012 91.6668 50 91.6668C47.6987 91.6668 45.8333 89.8014 45.8333 87.5002V75.0002C45.8333 72.6989 47.6987 70.8335 50 70.8335ZM91.6667 50.0002C91.6667 52.3014 89.8013 54.1668 87.5 54.1668H75C72.6988 54.1668 70.8333 52.3014 70.8333 50.0002C70.8333 47.6989 72.6988 45.8335 75 45.8335H87.5C89.8013 45.8335 91.6667 47.6989 91.6667 50.0002ZM29.1667 50.0002C29.1667 52.3014 27.3012 54.1668 25 54.1668H12.5C10.1988 54.1668 8.33333 52.3014 8.33333 50.0002C8.33333 47.6989 10.1988 45.8335 12.5 45.8335H25C27.3012 45.8335 29.1667 47.6989 29.1667 50.0002ZM79.4629 79.4631C77.8354 81.0902 75.1975 81.0902 73.5704 79.4631L64.7312 70.6239C63.1042 68.9968 63.1042 66.3589 64.7312 64.7314C66.3587 63.1043 68.9967 63.1043 70.6237 64.7314L79.4629 73.5706C81.09 75.1977 81.09 77.8356 79.4629 79.4631ZM35.2686 35.2688C33.6414 36.896 31.0032 36.896 29.376 35.2688L20.5372 26.43C18.91 24.8027 18.91 22.1646 20.5372 20.5374C22.1644 18.9102 24.8026 18.9102 26.4298 20.5374L35.2686 29.3762C36.8958 31.0034 36.8958 33.6416 35.2686 35.2688ZM20.5372 79.4631C18.91 77.8356 18.91 75.1977 20.5372 73.5706L29.376 64.7314C31.0032 63.1043 33.6414 63.1043 35.2686 64.7314C36.8958 66.3589 36.8958 68.9968 35.2686 70.6239L26.4298 79.4631C24.8026 81.0902 22.1644 81.0902 20.5372 79.4631ZM64.7312 35.2688C63.1042 33.6416 63.1042 31.0034 64.7312 29.3762L73.5704 20.5374C75.1975 18.9102 77.8354 18.9102 79.4629 20.5374C81.09 22.1646 81.09 24.8027 79.4629 26.43L70.6237 35.2688C68.9967 36.896 66.3587 36.896 64.7312 35.2688Z",
      fill: "currentColor"
    })
  })
});
var Spinner_default = SvgSpinner;

// src/atoms/loaders/Spinner/styles.tsx
var import_material29 = require("@mui/material");
var spinnerAnimation = import_material29.keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
var rotatedBoxStyle = {
  animation: `${spinnerAnimation} 4s infinite linear`
};
var containerStyle2 = {
  display: "flex",
  justifyContent: "center"
};

// src/atoms/loaders/Spinner/index.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var Spinner = ({ color = "primary.main", sx = [] }) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Box10.default, {
  sx: [containerStyle2, ...Array.isArray(sx) ? sx : [sx]],
  color,
  children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_Box10.default, {
    sx: rotatedBoxStyle,
    "data-testid": "spinner",
    children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Spinner_default, {
      fill: "inherit"
    })
  })
});
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  BackdropLoader,
  BasicDialog,
  Button,
  Card,
  Checkbox,
  Chip,
  CodeVerification,
  ControlledCheckbox,
  ControlledDatePicker,
  ControlledSelect,
  ControlledTextField,
  Currency,
  CustomAccordion,
  CustomLink,
  DatePicker,
  EditButton,
  FailedDialog,
  FieldWithButton,
  Header,
  InfoBar,
  InputLabel,
  LinearProgress,
  LoadingDialog,
  MainAccordion,
  PhoneNumber,
  RadioAccordionGroup,
  RequiredSign,
  Select,
  SimpleCard,
  Spinner,
  StepTitle,
  Stepper,
  StyledBackdrop,
  TextField
});
