import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'
import ApiEndpoints from 'api/endpoints'
import { useGetOrderVerifyCustomer, usePostCreateOrder } from 'components/pages/api'
import { ExternalLinks, GeneralRoute, STORAGE_ORDER_PRODUCTS, StepRoute } from 'consts'
import { NotificationsContext, notifyError } from 'context'
import { useExtensionTokenSearch } from 'hooks'
import { ICreateCustomerOrder, ICustomerOrder } from 'models'

interface CreateOrder {
  externalId?: string
  returnURL: string | undefined
  webookExtensionEnabledToken?: string
}

export const useCreateOrder = ({ returnURL, webookExtensionEnabledToken }: CreateOrder) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { extensionTokenSearch } = useExtensionTokenSearch()

  const { dispatchNotification } = useContext(NotificationsContext)
  const intervalIDRef = useRef<any>(null)

  const [externalId, setExternalId] = useState('')

  const {
    data: verifyCustomerData,
    isLoading: verifyCustomerIsLoading,
    isSuccess: verifyCustomerIsSuccess,
    isError: verifyCustomerIsError,
    refetch: refetchGetVerifyCustomer,
  } = useGetOrderVerifyCustomer(externalId)

  useEffect(() => {
    if (externalId) {
      // check verification api output
      if (
        verifyCustomerIsSuccess &&
        verifyCustomerData?.agreementClientUrl &&
        verifyCustomerData?.externalRequestType &&
        verifyCustomerData?.contractEligible
      ) {
        redirectToContractGeneration()
      } else if (verifyCustomerIsSuccess && !verifyCustomerData?.contractEligible) {
        clearInterval(intervalIDRef.current)
        intervalIDRef.current = null
        notifyError(dispatchNotification, {
          title: formatMessage({ id: 'notification.dear_customer' }),
          content: formatMessage({ id: 'notification.data_verification_title_check_error' }),
          actions: [
            {
              label: formatMessage({ id: 'button.go_to_the_store' }),
              onClick: () => window.location.replace(returnURL ?? ExternalLinks.WESUB),
            },
          ],
        })
      }
      // if output is not correct call retry in loop
      else if (verifyCustomerIsSuccess && !(verifyCustomerData?.agreementClientUrl && verifyCustomerData?.externalRequestType)) {
        verifyCustomerCounter()
      }
      // if error occurred
      else if (verifyCustomerIsError && !verifyCustomerIsSuccess && !verifyCustomerIsLoading) {
        // clear interval
        clearInterval(intervalIDRef.current)
        intervalIDRef.current = null
        // display error notification
        notifyError(dispatchNotification, {
          title: formatMessage({ id: 'notification.dear_customer' }),
          content: formatMessage({ id: 'notification.create_order_default_error' }),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalId, verifyCustomerData, verifyCustomerIsSuccess, verifyCustomerIsError, verifyCustomerIsLoading])

  const redirectToContractGeneration = useCallback(() => {
    // clear interval
    clearInterval(intervalIDRef.current)
    // remove order data in the storage
    sessionStorage.removeItem(STORAGE_ORDER_PRODUCTS)
    // * clear gerOrder query cache
    const queryKey = ApiEndpoints.getOrder(externalId).queryKey
    queryClient.removeQueries({ queryKey })
    // navigate
    setTimeout(() => {
      navigate({
        pathname: `/${GeneralRoute.ORDER}/${externalId}/${StepRoute.CONTRACT_GENERATION}`,
        search: extensionTokenSearch,
      })
    }, 2000)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalId])

  const verifyCustomerCounter = useCallback(() => {
    let currentStep = 0
    const maxStep = 20

    intervalIDRef.current = setInterval(() => {
      currentStep += 1

      if (currentStep > maxStep) {
        clearInterval(intervalIDRef.current)
        intervalIDRef.current = null

        notifyError(dispatchNotification, {
          title: formatMessage({ id: 'notification.dear_customer' }),
          content: formatMessage({ id: 'notification.data_verification_title_couldnt_check' }),
          actions: [
            {
              label: formatMessage({ id: 'button.close' }),
              onClick: () => window.location.replace(returnURL ?? ExternalLinks.WESUB),
            },
          ],
        })
      } else {
        refetchGetVerifyCustomer()
      }
    }, 5000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnURL])

  useEffect(() => {
    return () => {
      if (intervalIDRef.current) {
        clearInterval(intervalIDRef.current)
        intervalIDRef.current = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const postGenerateOrderContract = usePostCreateOrder(webookExtensionEnabledToken ?? '')

  const createOrder = async (data: ICreateCustomerOrder) => {
    await postGenerateOrderContract.mutate(data, {
      onSuccess: ({ data }: { data: ICustomerOrder }) => {
        setExternalId(data.externalId ?? '')
      },
      onError: () =>
        notifyError(dispatchNotification, {
          title: formatMessage({ id: 'notification.dear_customer' }),
          content: formatMessage({ id: 'notification.create_order_default_error' }),
        }),
    })
  }

  return { createOrder, dispatchNotification }
}
