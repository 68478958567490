import { QueryKey } from '@tanstack/react-query'
import { ResponseType } from 'axios'
import { AGREEMENT_TYPE } from 'models'

type HttpMethod = 'GET' | 'POST' | 'PUT'
interface Endpoint {
  url: string
  method?: HttpMethod
  responseType?: ResponseType
  queryKey?: QueryKey
}

/**
 * Central SoT/Repo for all BE endpoints called from Rent apps
 * Use this obj to keep in a shared and unique place
 * all endpoints and call them in a more simpler way
 */
const ApiEndpoints = {
  getOrder: (orderId: string) => BaseKey(`/api/orders/uuid/${orderId}`, { method: 'GET' }),
  getVendor: (vendorId: string) => BaseKey(`/api/vendors/extension/id/${vendorId}`, { method: 'GET' }),

  getOrderVerifyCustomer: (orderId: string) => BaseKey(`/api/orders/external/request/entry/${orderId}`, { method: 'GET' }),

  getSaveCustomerAgreementData: (data: string) => BaseKey(`/api/orders/agreement/template/display/error/${data}`, { method: 'GET' }),

  getShowPayment: (orderId: string) => BaseKey(`/api/orders/uuid/${orderId}/showpayment`, { method: 'GET' }),
  getBlueMediaLink: (orderId: string) => BaseKey(`/api/orders/blue/media/url/${orderId}`, { method: 'GET' }),
  getAgreementTemplate: (agreementType: AGREEMENT_TYPE) =>
    BaseKey(`/api/orders/pdf/online/${agreementType}`, { method: 'GET', responseType: 'blob' }),
  getPaymentInfo: (orderId: string) => BaseKey(`/api/orders/uuid/${orderId}/payment-info`, { method: 'GET' }),

  postPromocode: () => BaseKey('/api/promotions/cart', { method: 'POST' }),
  postSuperPromocode: () => BaseKey('/api/super/promotions/cart', { method: 'POST' }),

  postCreateOrder: (token: string) => BaseKey(`/api/v2/processOrder${token && '?token=' + token}`, { method: 'POST' }),
  postGenerateOrderContract: (token: string) =>
    BaseKey(`/api/orders/agreement/template/anonymous${token && '?token=' + token}`, { method: 'POST' }),
  postAcceptOffer: (orderId: string) => BaseKey(`/api/orders/accept-offer/${orderId}`, { method: 'POST' }),

  postPrepareOrderSecDetails: (orderId: string) => BaseKey(`/api/order-sec-details/extension/${orderId}`, { method: 'POST' }),

  postAcceptContract: () => BaseKey('/api/v2/acceptAgreement', { method: 'POST' }),

  postVerifyReplacementAgreementNo: () => BaseKey('/api/orders/replacement-agreement-validator', { method: 'POST' }),

  getCheckClientConsents: (nip: string) => BaseKey(`/api/customer-orders/communication-acceptance/${nip}`, { method: 'GET' }),

  postCancelNewOrder: (orderId: string) => BaseKey(`/api/orders/${orderId}/cancel-order`, { method: 'POST' }),

  postGenerateVerificationCode: () => BaseKey('/api/orders/code-generator', { method: 'POST' }),
  postVerifyVerificationCode: () => BaseKey('/api/orders/code-verify', { method: 'POST' }),

  // plugin
  getPluginVendorData: (bannerId: string) => BaseKey(`/api/vendors/extension/external/banner-param/${bannerId}`, { method: 'GET' }),
  postPluginProductData: () => BaseKey('/api/products/extension/clientproduct', { method: 'POST' }),
  updateBannerAuditStatus: () => BaseKey('/api/orders/banner/status', { method: 'POST' }),
  createOrder: () => BaseKey('/api/orders', { method: 'POST' }),

  getCustomerMaskedData: (nip: string, token: string) => BaseKey(`/api/v2/order/extension/data/${nip}?token=${token}`, { method: 'GET' }),
}

const ApiExternalEndpoints = {
  getGeolocation: () => BaseKey('https://geolocation-db.com/json/', { method: 'POST' }),
}

/**
 * Simple Helper to obtain a common structure for endpoints
 * @param path = endpoint to be called
 * @returns an object with a 'url' key and an optional HttpMethod
 */
const BaseUrl = (path: string, method?: HttpMethod, responseType?: ResponseType): Endpoint => ({ url: path, method, responseType })
// const BaseUrl = (path: string, method?: HttpMethod): Endpoint => ({ url: `${API_BASE_URL}${path}`, method })
/**
 * Helper to obtain a common structure for endpoints and queryKey
 * @param path endpoint to be called
 * @param customKey (optional) custom Query Key
 * @returns an object with an url and a querykey keys
 */
const BaseKey = (path: string, arg?: { method?: HttpMethod; queryKey?: QueryKey; responseType?: ResponseType }): Endpoint => ({
  queryKey: arg?.queryKey ?? path.split('/').filter(value => value.length),
  ...BaseUrl(path, arg?.method, arg?.responseType),
})

export default ApiEndpoints
export { ApiExternalEndpoints }
