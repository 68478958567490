import React, { useCallback } from 'react'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { ControlledCheckbox, ControlledDatePicker, ControlledSelect, ControlledTextField } from '@rent/ui'
import { FormInputFieldWithProperty } from 'models'

type FormGeneratorProps = {
  onSubmitForm?: SubmitHandler<any>
} & FormInputFieldWithProperty

const FormFieldGenerator = ({ fieldName, properties, onSubmitForm }: FormGeneratorProps) => {
  const intl = useIntl()

  const { control, handleSubmit } = useFormContext()
  const renderField = useCallback(
    (field: FormInputFieldWithProperty) => {
      const isDisabled = field.properties.inputProps?.disabled || field.properties.disabled

      const label = field.properties.customLabel
        ? intl.formatMessage({ id: field.properties.customLabel.id }, field.properties.customLabel.values)
        : intl.formatMessage({ id: `form.${field.fieldName}_label` })

      const labelExpanded = () =>
        field.properties.customLabelExpanded
          ? intl.formatMessage({ id: field.properties.customLabelExpanded.id }, field.properties.customLabelExpanded.values)
          : intl.formatMessage({ id: `form.${field.fieldName}_label_expanded` })

      switch (field.properties.type) {
        case 'checkbox':
          return (
            <ControlledCheckbox
              fieldName={field.fieldName}
              label={label}
              required={field.properties?.required}
              control={control}
              {...field.properties}
              type="checkbox"
            />
          )
        case 'checkbox-accordion':
          return (
            <ControlledCheckbox
              fieldName={field.fieldName}
              label={label}
              required={field.properties?.required}
              control={control}
              {...field.properties}
              type="checkbox-accordion"
              labelExpanded={labelExpanded()}
            />
          )
        case 'select':
          return (
            <ControlledSelect
              fieldName={field.fieldName}
              inputProps={{
                ...field.properties.inputProps,
                name: field.fieldName,
              }}
              label={label}
              helperText={field.properties.helperText ? intl.formatMessage({ id: `form.${field.properties.helperText}` }) : ''}
              placeholder={isDisabled ? '' : intl.formatMessage({ id: `form.${field.fieldName}_placeholder` })}
              control={control}
              options={field.properties.options ?? []}
              {...field.properties}
            />
          )
        case 'datepicker':
          return (
            <ControlledDatePicker
              fieldName={field.fieldName}
              label={label}
              helperText={field.properties.helperText ? intl.formatMessage({ id: `form.${field.properties.helperText}` }) : ''}
              control={control}
              onPressEnter={typeof onSubmitForm === 'function' ? handleSubmit(onSubmitForm) : undefined}
              {...field.properties}
            />
          )
        default:
          return (
            <ControlledTextField
              fieldName={field.fieldName}
              inputProps={{
                ...field.properties.inputProps,
                name: field.fieldName,
              }}
              label={label}
              helperText={field.properties.helperText ? intl.formatMessage({ id: `form.${field.properties.helperText}` }) : ''}
              placeholder={isDisabled ? '' : intl.formatMessage({ id: `form.${field.fieldName}_placeholder` })}
              type={field.properties.type}
              control={control}
              onPressEnter={typeof onSubmitForm === 'function' ? handleSubmit(onSubmitForm) : undefined}
              {...field.properties}
            />
          )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [control, intl, properties, onSubmitForm],
  )

  return <> {renderField({ fieldName, properties })}</>
}

export default FormFieldGenerator
