import { useMemo } from 'react'

import { RouteQueryParam, WEBOOK_EXTENSION_PARAM, webookExtensionParams } from 'consts'

import { useRouteQuery } from './useRouteQuery'

export const useExtensionTokenSearch = () => {
  const routerQuery = useRouteQuery()
  const webookExtensionParam = routerQuery.get(RouteQueryParam.REF)
  const webookExtensionTokenParam = routerQuery.get(RouteQueryParam.TOKEN)

  const extensionTokenSearch = useMemo(() => {
    return webookExtensionParams(webookExtensionParam, webookExtensionTokenParam)
  }, [webookExtensionParam, webookExtensionTokenParam])

  return {
    extensionTokenSearch,
    webookExtensionTokenParam: webookExtensionParam === WEBOOK_EXTENSION_PARAM ? String(webookExtensionTokenParam) : '',
  }
}
