import { WEBOOK_EXTENSION_SALT } from 'consts'

export const generateSHA256Digest = async (nip: number, transactionType: string) => {
  const digest = `${WEBOOK_EXTENSION_SALT}${nip}${transactionType}`
  const encoder = new TextEncoder()
  const data = encoder.encode(digest)
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)

  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const base64String = btoa(String.fromCharCode(...hashArray))

  const urlSafeBase64 = base64String.replace(/\+/g, '-').replace(/\//g, '_')
  return urlSafeBase64
}
