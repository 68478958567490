import { useContext, useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, UseFormSetValue, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { zodResolver } from '@hookform/resolvers/zod'
import Box from '@mui/material/Box'
import { Button, InfoBar, StepTitle } from '@rent/ui'
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/ArrowForward.svg'
import { StepContentLayout } from 'components/layouts'
import { NotReadyComponent, OrderSummaryPanel } from 'components/shared'
import { ErrorCodeType, GeneralRoute, OrderStatus, StepRoute } from 'consts'
import { NotificationsContext, OrderContext, notifyLoading } from 'context'
import { useExtensionTokenSearch, useWeBookExtension } from 'hooks'
import { CustomerMaskedData, CustomerPersonalDataFields, ICustomerOrder } from 'models'

import { prepareCreateOrderData } from '../api/usePostCreateOrder'
import { useCancelNewOrder, useOrderData, useStorageOrderProductsData, useVendorData } from '../hooks'
import FormData from './components/FormData'
import { ValidationSchema, formInitValues, validationSchema } from './consts'
import { useCheckClientConsents, useCreateOrder } from './hooks'

const PersonalData = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { extensionTokenSearch } = useExtensionTokenSearch()

  const { dispatchNotification } = useContext(NotificationsContext)
  const { contextData } = useContext(OrderContext)

  // get order data
  const { data, isLoading, isSuccess, isError, error, refetchOrderData } = useOrderData({
    status: [OrderStatus.NEW, OrderStatus.PRE_ORDER],
    isUseContextData: true,
  })
  // get vendor data and set logo
  useVendorData({ vendorId: data?.vendorId })

  const { orderProductsData } = useStorageOrderProductsData({
    items: data?.items,
    orderId: data?.externalId,
    refetchOrderData: () =>
      navigate({
        pathname: `/${GeneralRoute.ORDER}/${data?.externalId}/${StepRoute.STORE_ORDER_SUMMARY}`,
        search: extensionTokenSearch,
      }),
  })

  const { customerMaskedData, customerMaskedDataIsLoading, webookExtensionEnabledToken } = useWeBookExtension({
    status: data?.status,
    nip: data?.nip,
    isExtension: data?.extension,
  })

  const methods = useForm<ValidationSchema>({
    defaultValues: formInitValues(data),
    resolver: zodResolver(validationSchema(formatMessage)),
    mode: 'onSubmit',
  })
  const { handleSubmit, setValue } = methods

  const { createOrder } = useCreateOrder({ externalId: data?.externalId ?? '', returnURL: data?.returnURL, webookExtensionEnabledToken })

  const [providedNip, setProvidedNip] = useState('')
  const { marketingConsents, clientConsentsIsLoading } = useCheckClientConsents({ providedNip })

  useCancelNewOrder({ orderId: data?.externalId ?? '', status: data?.status, refetchOrderData })

  useEffect(() => {
    if (data) {
      prefillFormData(data, setValue, customerMaskedData)
    }
  }, [data, customerMaskedData, setValue])

  const onSubmit: SubmitHandler<ValidationSchema> = async formData => {
    notifyLoading(dispatchNotification, {
      title: formatMessage({ id: 'notification.data_verification_title' }),
      content: formatMessage({ id: 'notification.please_wait_several_sec' }),
    })
    const mappedData = prepareCreateOrderData(data?.externalId ?? '', formData, orderProductsData, {
      apiBannerParam: '',
      bannerGeneratedId: '',
      applicationName: 'API',
      vendorId: data?.vendorId,
    })
    createOrder(mappedData)
  }

  return (
    <Box>
      <NotReadyComponent
        isLoading={isLoading || customerMaskedDataIsLoading}
        isSuccess={isSuccess}
        isError={isError}
        error={error}
        errorType={ErrorCodeType.ORDER_ERROR}
        redirect
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StepContentLayout
              // right panel
              rightSide={
                <OrderSummaryPanel
                  items={orderProductsData}
                  actionButton={
                    <Button
                      fullWidth
                      type="submit"
                      id="goToAgreementGeneration-button"
                      loading={clientConsentsIsLoading}
                      endIcon={<ArrowForwardIcon />}
                    >
                      {formatMessage({ id: 'button.go_further' })}
                    </Button>
                  }
                />
              }
            >
              <>
                {/* title */}
                <StepTitle
                  title={`2. ${formatMessage({ id: 'personal_data' })}`}
                  subtitle={formatMessage({ id: 'message.the_process_consists_of_steps' }, { number_of_steps: 3 })}
                >
                  <Box sx={{ maxWidth: 500 }}>
                    {data?.extension && customerMaskedData ? (
                      <InfoBar title={formatMessage({ id: 'message.extension_data_automatically_filled' })} align="left" iconSize={1.6} />
                    ) : (
                      <InfoBar title={formatMessage({ id: 'message.prepare_id_card' })} align="left" iconSize={1.6} />
                    )}
                  </Box>
                </StepTitle>

                {/* form data */}
                {data && (
                  <FormData
                    onSubmitForm={onSubmit}
                    orderStatus={data.status ?? ''}
                    extension={Boolean(contextData.isExtension)}
                    setProvidedNip={setProvidedNip}
                    marketingConsents={marketingConsents}
                    customerMaskedData={customerMaskedData}
                  />
                )}
              </>
            </StepContentLayout>
          </form>
        </FormProvider>
      </NotReadyComponent>
    </Box>
  )
}

export const prefillFormData = (
  data: ICustomerOrder,
  setValue: UseFormSetValue<any>,
  customerMaskedData: CustomerMaskedData | null | undefined,
) => {
  Object.values(CustomerPersonalDataFields).forEach(el => {
    if (el === 'nip') {
      setValue(el, data?.nip && data?.nip !== 0 ? data?.nip.toString() : '')
    } else {
      if (customerMaskedData) {
        setValue(`editableFields.${el}`, true)
        setValue(el, customerMaskedData[el] ?? '')
      } else setValue(el, data[el] ?? '')
    }
  })
}

export default PersonalData
