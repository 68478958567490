"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/config/index.ts
var config_exports = {};
__export(config_exports, {
  muiConfig: () => muiConfig
});
module.exports = __toCommonJS(config_exports);

// src/config/theme/breakpoints.ts
var breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1400,
    xxl: 1600
  }
};

// src/config/theme/colors.ts
var colors = {
  primary: {
    light: "#DB1464",
    main: "#F30464",
    dark: "#DB1464"
  },
  secondary: {
    main: "#2522a1"
  },
  success: {
    main: "#22D263"
  },
  warning: {
    main: "#F96818"
  },
  error: {
    main: "#F34040"
  },
  background: {
    paper: "#fff",
    default: "#F9FAFB"
  },
  text: {
    primary: "#101C2D",
    secondary: "#66768E",
    disabled: "#667085"
  },
  border: {
    default: "#EAECF0"
  },
  grey: {
    50: "#F9FAFB",
    100: "#F1F4F9",
    200: "#DDE4EE",
    300: "#CCD4E0",
    400: "#98A2B3",
    500: "#66768E",
    600: "#475467",
    700: "#344054",
    800: "#1D2939",
    900: "#101C2D"
  }
};

// src/config/theme/components/MuiButton.tsx
var MuiButton = {
  styleOverrides: {
    root: {
      textTransform: "none",
      padding: "1.2rem 1.6rem"
    },
    contained: {
      color: "white"
    },
    sizeMedium: {
      minWidth: "unset"
    },
    sizeSmall: {
      padding: "1.2rem 1.6rem"
    }
  }
};

// src/config/theme/components/MuiChip.tsx
var MuiChip = {
  styleOverrides: {
    root: {
      borderRadius: ".8rem"
    }
  }
};

// src/config/theme/components/MuiCssBaseline.tsx
var MuiCssBaseline = {
  styleOverrides: {
    html: {
      scrollBehavior: "smooth",
      fontSize: "10px",
      "@media (max-width: 900px)": {
        fontSize: "9.25px"
      },
      "@media (max-width: 600px)": {
        fontSize: "8.75px"
      },
      "& *": {
        scrollbarWidth: "thin",
        scrollbarColor: `${colors.grey[500]} ${colors.grey[200]}`
      },
      "& *::-webkit-scrollbar": {
        width: ".4rem",
        height: ".4rem"
      },
      "& *::-webkit-scrollbar-track": {
        background: colors.grey[200],
        borderRadius: ".8rem"
      },
      "& *::-webkit-scrollbar-thumb": {
        backgroundColor: colors.grey[500],
        borderRadius: ".8rem"
      }
    }
  }
};

// src/config/theme/helpers.tsx
function pxToRem(value, baseValue = 10) {
  return `${value / baseValue}rem`;
}

// src/config/theme/typography.ts
var customFontFamily = '"Manrope", "Roboto", "Montserrat", "Helvetica", "Arial", sans-serif';
var _a;
var typography = {
  htmlFontSize: 10,
  fontFamily: '"Roboto", "Montserrat", "Helvetica", "Arial", sans-serif',
  h1: {
    fontSize: pxToRem(40),
    lineHeight: 1.4,
    fontWeight: 700
  },
  h2: {
    fontSize: pxToRem(28),
    lineHeight: 1.429,
    fontWeight: 700,
    fontFamily: customFontFamily
  },
  h3: {
    fontSize: pxToRem(24),
    lineHeight: 1.25,
    fontWeight: 700
  },
  h4: {
    fontSize: pxToRem(20),
    lineHeight: 1.4,
    fontWeight: 700,
    fontFamily: customFontFamily
  },
  h5: {
    fontSize: pxToRem(18),
    lineHeight: 1.333,
    fontWeight: 700,
    fontFamily: customFontFamily,
    letterSpacing: "0.02rem"
  },
  h6: {
    fontSize: pxToRem(16),
    lineHeight: 1.625,
    fontWeight: 700,
    fontFamily: customFontFamily,
    letterSpacing: "0.02rem"
  },
  subtitle1: {
    fontSize: pxToRem(16),
    lineHeight: 1.625,
    fontWeight: 700
  },
  subtitle2: {
    fontSize: pxToRem(12),
    lineHeight: 1.333,
    color: (_a = colors.text) == null ? void 0 : _a.secondary
  },
  body1: {
    fontSize: pxToRem(16),
    lineHeight: 1.625
  },
  body2: {
    fontSize: pxToRem(14),
    lineHeight: 1.571
  },
  button: {
    fontSize: pxToRem(16),
    lineHeight: 1.857,
    fontWeight: 700
  },
  caption: {
    fontSize: pxToRem(12),
    lineHeight: 1.333
  },
  overline: {
    fontSize: pxToRem(13)
  }
};

// src/config/theme/components/MuiOutlinedInput.tsx
var MuiOutlinedInput = {
  styleOverrides: {
    input: ({ theme }) => {
      var _a3;
      return {
        ...theme.typography.body2,
        background: (_a3 = theme.palette.background) == null ? void 0 : _a3.paper,
        padding: "1.1rem 1.6rem",
        "&.Mui-disabled": {
          background: theme.palette.grey[100]
        }
      };
    },
    root: ({ ownerState, theme }) => {
      return {
        ...typography.body2,
        "&.Mui-disabled": {
          background: theme.palette.grey[100]
        },
        ...!ownerState.error && !ownerState.disabled && {
          "&:hover fieldset": {
            borderColor: `${theme.palette.grey[500]} !important`
          }
        },
        ...ownerState.focused && !ownerState.error && {
          "& fieldset": {
            borderColor: `${theme.palette.grey[500]} !important`
          }
        }
      };
    }
  }
};

// src/config/theme/components/MuiPaper.tsx
var _a2;
var MuiPaper = {
  styleOverrides: {
    root: {
      border: `.1rem solid ${(_a2 = colors.grey) == null ? void 0 : _a2[200]}`
    }
  }
};

// src/config/theme/components/index.tsx
var components = {
  MuiButton,
  MuiPaper,
  MuiOutlinedInput,
  MuiChip,
  MuiCssBaseline,
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true
    }
  }
};

// src/config/theme/otherConfig.ts
var otherConfig = {
  shadows: Array(25).fill("none"),
  spacing: 10,
  shape: {
    borderRadius: 8
  },
  functions: {
    pxToRem
  }
};

// src/config/theme/index.ts
var muiConfig = {
  breakpoints,
  colors,
  typography,
  components,
  otherConfig,
  customFontFamily
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  muiConfig
});
