import { Link } from '@mui/material'
import { validatePolish } from '@rent/ui/helpers'
import { ExternalLinks, GeneralRoute, MAX_TEXT_LENGTH, OrderStatus, PHONE_REGEXP_PL } from 'consts'
import {
  CustomerPersonalDataConsentsFields,
  CustomerPersonalDataFields,
  ICustomerOrder,
  OnboardingCustomerFieldsPropertiesType,
} from 'models'
import { z } from 'zod'

import { MarketingConsents } from './hooks'

export const validationSchema = (formatMessage: ({ id }: { id: string }) => string) =>
  z
    .object({
      [CustomerPersonalDataFields.firstName]: z
        .string()
        .trim()
        .min(1, { message: formatMessage({ id: 'notification.required_field' }) }),

      [CustomerPersonalDataFields.lastName]: z
        .string()
        .trim()
        .min(1, { message: formatMessage({ id: 'notification.required_field' }) }),

      [CustomerPersonalDataFields.nip]: z
        .string()
        .min(1, { message: formatMessage({ id: 'notification.required_field' }) })
        .refine(value => validatePolish.nip(value), { message: formatMessage({ id: 'notification.nip_invalid' }) }),

      [CustomerPersonalDataFields.buyerEmail]: z.string().min(1, { message: formatMessage({ id: 'notification.required_field' }) }),

      [CustomerPersonalDataFields.buyerPhone]: z.string().min(1, { message: formatMessage({ id: 'notification.required_field' }) }),

      // helper
      editableFields: z.object({
        [CustomerPersonalDataFields.firstName]: z.boolean(),
        [CustomerPersonalDataFields.lastName]: z.boolean(),
        [CustomerPersonalDataFields.buyerEmail]: z.boolean(),
        [CustomerPersonalDataFields.buyerPhone]: z.boolean(),
      }),
      // END helper

      // checkboxes
      [CustomerPersonalDataConsentsFields.selectAll]: z.boolean(),

      [CustomerPersonalDataConsentsFields.agreementRodo]: z.boolean().refine(val => val, {
        message: formatMessage({ id: 'notification.required_field' }),
      }),

      [CustomerPersonalDataConsentsFields.agreementStatute]: z.boolean().refine(val => val, {
        message: formatMessage({ id: 'notification.required_field' }),
      }),

      [CustomerPersonalDataConsentsFields.agreementKrd]: z.boolean().refine(val => val, {
        message: formatMessage({ id: 'notification.required_field' }),
      }),

      [CustomerPersonalDataConsentsFields.agreementEmailCommunication]: z.boolean(),
      [CustomerPersonalDataConsentsFields.agreementPhoneCommunication]: z.boolean(),
    })
    .superRefine(({ firstName, lastName, buyerEmail, buyerPhone, editableFields }, ctx) => {
      if (!editableFields[CustomerPersonalDataFields.firstName] && firstName?.trim().length > MAX_TEXT_LENGTH['SMALL']) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: formatMessage({ id: 'notification.max_length_small' }),
          path: [CustomerPersonalDataFields.firstName],
        })
      }
      if (!editableFields[CustomerPersonalDataFields.lastName] && lastName?.trim().length > MAX_TEXT_LENGTH['SMALL']) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: formatMessage({ id: 'notification.max_length_small' }),
          path: [CustomerPersonalDataFields.lastName],
        })
      }
      if (!editableFields[CustomerPersonalDataFields.buyerEmail] && !z.string().email().safeParse(buyerEmail).success) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: formatMessage({ id: 'notification.email_invalid' }),
          path: [CustomerPersonalDataFields.buyerEmail],
        })
      }
      if (!editableFields[CustomerPersonalDataFields.buyerPhone] && !PHONE_REGEXP_PL.test(buyerPhone)) {
        ctx.addIssue({
          path: [CustomerPersonalDataFields.buyerPhone],
          code: z.ZodIssueCode.custom,
          message: formatMessage({ id: 'notification.phoneNumber_invalid_pl' }),
        })
      }
    })

export type ValidationSchema = z.infer<ReturnType<typeof validationSchema>>

export const customerPersonalDataFieldsProperties = (orderStatus: OrderStatus | ''): OnboardingCustomerFieldsPropertiesType => ({
  [CustomerPersonalDataFields.firstName]: {
    type: 'text',
    inputProps: { fullWidth: true },
  },
  [CustomerPersonalDataFields.lastName]: {
    type: 'text',
    inputProps: { fullWidth: true },
  },
  [CustomerPersonalDataFields.nip]: {
    type: 'text',
    inputProps: { fullWidth: true, disabled: orderStatus === OrderStatus.PRE_ORDER },
  },
  [CustomerPersonalDataFields.buyerEmail]: {
    type: 'email',
    inputProps: { fullWidth: true },
  },
  [CustomerPersonalDataFields.buyerPhone]: {
    type: 'phone',
    inputProps: { fullWidth: true },
  },
})

export const customerPersonalDataConsentsFieldsProperties = (
  marketingConsents: MarketingConsents,
  formatMessage: ({ id }: { id: string }) => string,
) => ({
  [CustomerPersonalDataConsentsFields.agreementRodo]: {
    type: 'checkbox',
    required: true,
    customLabel: {
      id: `form.${CustomerPersonalDataConsentsFields.agreementRodo}_label`,
      values: {
        link: (
          <Link target="_blank" href={ExternalLinks.RODO} rel="noreferrer" color="text.primary" id="rodo-link">
            {formatMessage({ id: 'rodo_info' })}
          </Link>
        ),
      },
    },
  },
  [CustomerPersonalDataConsentsFields.agreementStatute]: {
    type: 'checkbox',
    required: true,
    customLabel: {
      id: `form.${CustomerPersonalDataConsentsFields.agreementStatute}_label`,
      values: {
        link: (
          <Link target="_blank" href={`/${GeneralRoute.TERMS}`} rel="noreferrer" color="text.primary" id="terms-link">
            {formatMessage({ id: 'statute_rentPay' })}
          </Link>
        ),
      },
    },
  },
  [CustomerPersonalDataConsentsFields.agreementKrd]: {
    type: 'checkbox-accordion',
    required: true,
  },
  [CustomerPersonalDataConsentsFields.agreementEmailCommunication]: {
    type: 'checkbox-accordion',
    disabled: marketingConsents.electronicProcessingAccepted,
  },
  [CustomerPersonalDataConsentsFields.agreementPhoneCommunication]: {
    type: 'checkbox-accordion',
    disabled: marketingConsents.phoneProcessingAccepted,
  },
})

export const formInitValues = (data: ICustomerOrder | null | undefined) => ({
  firstName: data?.firstName ?? '',
  lastName: data?.lastName ?? '',
  buyerEmail: data?.buyerEmail ?? '',
  buyerPhone: data?.buyerPhone ?? '',
  nip: data?.nip ? data?.nip.toString() : '',
  selectAll: false,
  agreementRodo: false,
  agreementStatute: false,
  agreementKrd: false,
  agreementEmailCommunication: false,
  agreementPhoneCommunication: false,

  editableFields: {
    [CustomerPersonalDataFields.firstName]: false,
    [CustomerPersonalDataFields.lastName]: false,
    [CustomerPersonalDataFields.buyerEmail]: false,
    [CustomerPersonalDataFields.buyerPhone]: false,
  },
})
