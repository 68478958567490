import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { ContractGenerationDataFields, CustomerMaskedData } from 'models'

interface WeBookExtensionData {
  buyerName?: string
  representantNamesOptions: { value: string }[]
  customerMaskedData?: CustomerMaskedData | null
}

export const useWeBookExtensionData = ({ customerMaskedData, buyerName, representantNamesOptions }: WeBookExtensionData) => {
  const { setValue, setFocus, control } = useFormContext()

  const linkExtensionUsed = useWatch({ control, name: 'linkExtensionUsed' })
  const editableFields = useWatch({ control, name: 'editableFields' })

  useEffect(() => {
    if (customerMaskedData) {
      setValue('linkExtensionUsed', true)
      setValue('editableFields.returnAccountNumber', true)
      setValue(ContractGenerationDataFields.returnAccountNumber, customerMaskedData.returnAccountNumber)
      setFocus(ContractGenerationDataFields.identityCardNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerMaskedData])

  useEffect(() => {
    if (linkExtensionUsed) {
      setValue(ContractGenerationDataFields.nameAndLastName, buyerName)
      setValue(ContractGenerationDataFields.pesel, customerMaskedData?.pesel ?? '')
      setValue(ContractGenerationDataFields.identityCardValidFrom, customerMaskedData?.identityCardValidFrom ?? '')
      setValue(ContractGenerationDataFields.identityCardValidTo, customerMaskedData?.identityCardValidTo ?? '')
      setFocus(ContractGenerationDataFields.identityCardNumber)
    } else {
      setValue(ContractGenerationDataFields.nameAndLastName, representantNamesOptions[0].value)
      setValue(ContractGenerationDataFields.pesel, '')
      setValue(ContractGenerationDataFields.identityCardValidFrom, '')
      setValue(ContractGenerationDataFields.identityCardValidTo, '')
      setFocus(ContractGenerationDataFields.pesel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkExtensionUsed])

  const onEditRepresentantDataClick = () => {
    setValue('linkExtensionUsed', !linkExtensionUsed)
  }

  const onEditFieldClick = (fieldName: string) => {
    setValue(`editableFields.${fieldName}`, !editableFields[fieldName])
    setTimeout(() => {
      setFocus(fieldName)
    }, 50)
  }

  return { onEditRepresentantDataClick, onEditFieldClick }
}
