export enum General {}

export const MAX_TEXT_LENGTH = {
  SMALL: 100,
  MEDIUM: 200,
  LARGE: 500,
}
export const PHONE_REGEXP = /^(\+(\d){2})?\d{6,11}$/s
export const PHONE_REGEXP_PL = /^(\+(\d){2})?\d{9}$/s

export const enum ExternalLinks {
  RODO = 'https://wesub.eu/pl/rodo',
  DOCUMENTS = 'https://wesub.eu/pl/dokumenty/',
  WESUB_STATUTE = 'https://wesub.eu/wp-content/uploads/2024/02/Regulamin-WeSmart-13.02.pdf',
  WESUB_TOIP = 'https://wesub.eu/wp-content/uploads/2024/02/TOiP-13.02.pdf',
  WESUB = 'https://wesub.eu/pl/',
  WEBOOK = 'https://webook.wesub.pl/',
}
export const enum InsuranceType {
  SAFE_UP = 'safeUp',
  SAFE_UP_PLUS = 'safeUpPlus',
}

export const STORAGE_SESSION_ID = 'session_id'
export const STORAGE_ORDER_PRODUCTS = 'order_products'
export const STORAGE_CONTRACT_GENERATION_DATA = 'contract_generation_data'
//
export const COOKIE_GENERAL = 'rentpay_www_cookie'
export const COOKIE_RISK_TRACKER = 'tracking_permission'
export const COOKIE_USER_ID = 'user_id'

export const WEBOOK_EXTENSION_SALT = 'tsLbBAD6'
export const WEBOOK_EXTENSION_PARAM = 'webook'

export const enum HttpCode {
  UNPROCESSABLE_ENTITY = 422,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}
export const enum ErrorCodeType {
  PAGE_FORBIDDEN = 'page_forbidden',
  PAGE_NOT_FOUND = 'page_not_found',
  ORDER_ID_NOT_FOUND = 'order_id_not_found',
  UNEXPECTED = 'unexpected',
  ORDER_ERROR = 'order_error',
  ORDER_CANCELED = 'order_canceled',
}

export const enum PaymentType {
  AUTOPAY_BUSINESS_ACCOUNT = 'autopay_business_account',
  AUTOPAY_OTHERS = 'autopay_others',
  DEBIT_NOTE = 'debitNote',
}

export const clearStorage = () => {
  sessionStorage.removeItem(STORAGE_ORDER_PRODUCTS)
  sessionStorage.removeItem(STORAGE_CONTRACT_GENERATION_DATA)
}
