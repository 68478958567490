"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/helpers/index.ts
var helpers_exports = {};
__export(helpers_exports, {
  formatCurrency: () => formatCurrency,
  formatIban: () => formatIban,
  formatIbanWithoutSpaces: () => formatIbanWithoutSpaces,
  formatPhoneNumber: () => formatPhoneNumber,
  formatPhoneNumberWithoutSeparator: () => formatPhoneNumberWithoutSeparator,
  validateAgreementNo: () => validateAgreementNo,
  validateIban: () => validateIban,
  validatePolish: () => validatePolish
});
module.exports = __toCommonJS(helpers_exports);

// src/helpers/formatCurrency.ts
var formatCurrency = (amount, showDecimals, currency, net, mth) => {
  let formattedAmount = Number(amount).toFixed(showDecimals ? 2 : 0);
  if (currency)
    formattedAmount += ` ${currency}`;
  if (net)
    formattedAmount += ` ${net}`;
  if (mth)
    formattedAmount += `/${mth}`;
  return formattedAmount;
};

// src/helpers/formatPhoneNumber.ts
var formatPhoneNumber = (phoneNumber, separator) => {
  var _a;
  const sep = separator != null ? separator : "-";
  const number = (_a = phoneNumber == null ? void 0 : phoneNumber.trim().replace(/[^0-9]/g, "")) != null ? _a : "";
  if (number.length < 4)
    return number;
  if (number.length < 7)
    return number.replace(/(\d{3})(\d{1})/, `$1${sep}$2`);
  if (number.length < 11)
    return number.replace(/(\d{3})(\d{3})(\d{1})/, `$1${sep}$2${sep}$3`);
  return number.replace(/(\d{3})(\d{4})(\d{4})/, `$1${sep}$2${sep}$3`);
};
var formatPhoneNumberWithoutSeparator = (phoneNumber) => {
  return phoneNumber.trim().replace(/[^0-9]/g, "").slice(0, 9);
};

// src/helpers/formatIban.tsx
var formatIban = (inputIBAN) => {
  const ibanWithoutSpaces = inputIBAN ? inputIBAN.trim().replace(/[^\d]/g, "") : "";
  const formattedIBAN = ibanWithoutSpaces.replace(/(\d{2})(?=\d)/, "$1 ").replace(/(\d{4})(?=\d)/g, "$1 ").trim();
  return formattedIBAN;
};
var formatIbanWithoutSpaces = (inputIBAN) => {
  return inputIBAN ? inputIBAN.trim().replace(/[^\d]/g, "").slice(0, 26) : "";
};

// src/helpers/validateIban.ts
var codeLength = {
  AD: 24,
  AE: 23,
  AT: 20,
  AZ: 28,
  BA: 20,
  BE: 16,
  BG: 22,
  BH: 22,
  BR: 29,
  CH: 21,
  CR: 21,
  CY: 28,
  CZ: 24,
  DE: 22,
  DK: 18,
  DO: 28,
  EE: 20,
  ES: 24,
  FI: 18,
  FO: 18,
  FR: 27,
  GB: 22,
  GI: 23,
  GL: 18,
  GR: 27,
  GT: 28,
  HR: 21,
  HU: 28,
  IE: 22,
  IL: 23,
  IS: 26,
  IT: 27,
  JO: 30,
  KW: 30,
  KZ: 20,
  LB: 28,
  LI: 21,
  LT: 20,
  LU: 20,
  LV: 21,
  MC: 27,
  MD: 24,
  ME: 22,
  MK: 19,
  MR: 27,
  MT: 31,
  MU: 30,
  NL: 18,
  NO: 15,
  PK: 24,
  PL: 28,
  PS: 29,
  PT: 25,
  QA: 29,
  RO: 24,
  RS: 22,
  SA: 24,
  SE: 24,
  SI: 19,
  SK: 24,
  SM: 27,
  TN: 24,
  TR: 26
};
function validateIban(accountNo, countryCode = "PL") {
  const iban = countryCode + accountNo.toString();
  const ibanArr = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
  if (!ibanArr || iban.length !== codeLength[countryCode]) {
    return false;
  }
  const digits = (ibanArr[3] + ibanArr[1] + ibanArr[2]).replace(/[A-Z]/g, (letter) => String(letter.charCodeAt(0) - 55));
  return mod97(digits) === "1";
}
function mod97(value) {
  let checksum = value.slice(0, 2);
  let fragment = "";
  for (let offset = 2; offset < value.length; offset += 7) {
    fragment = checksum + value.substring(offset, offset + 7);
    checksum = (parseInt(fragment, 10) % 97).toString();
  }
  return checksum;
}

// src/helpers/validatePolish.ts
var validatePolish = {
  checksum: (number, weights) => {
    const max = number.length - 1;
    let sum = 0;
    for (let i = 0; i < max; ++i) {
      const n = parseInt(number.charAt(i), 10);
      sum += n * weights[i];
    }
    const control = sum % 11;
    const resultSum = control === 10 ? 0 : control;
    const lastDigit = parseInt(number.slice(-1), 10);
    return resultSum === lastDigit;
  },
  pesel(pesel) {
    const monthWithCentury = Number(pesel.substring(2, 4));
    if (!monthWithCentury || monthWithCentury % 20 > 12) {
      return false;
    }
    const day = Number(pesel.substring(4, 6));
    if (!day || day < 1 || day > 31) {
      return false;
    }
    if (!/^[0-9]{11}$/u.test(pesel)) {
      return false;
    }
    const times = [1, 3, 7, 9];
    const digits = `${pesel}`.split("").map((digit) => {
      return parseInt(digit, 10);
    });
    const [dig11] = digits.splice(-1);
    const control = digits.reduce((previousValue, currentValue, index) => {
      return previousValue + currentValue * times[index % 4];
    }) % 10;
    return 10 - (control === 0 ? 10 : control) === dig11;
  },
  nip(nip) {
    if (typeof nip !== "string") {
      return false;
    }
    const nipWithoutDashes = nip.replace(/-/gu, "");
    const reg = /^[0-9]{10}$/u;
    if (!reg.test(nipWithoutDashes)) {
      return false;
    }
    const dig = String(nipWithoutDashes).split("");
    const controlValues = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    const partialSums = controlValues.map((controlValue, index) => {
      return controlValue * parseInt(dig[index], 10);
    });
    let sum = 0;
    partialSums.forEach((partialSum) => {
      sum += partialSum;
    });
    const control = sum % 11;
    if (parseInt(dig[9], 10) === control) {
      return true;
    }
    return false;
  },
  regon(regon) {
    const reg = /^[0-9]{9,14}$/u;
    if (!reg.test(regon)) {
      return false;
    }
    const weights9 = [8, 9, 2, 3, 4, 5, 6, 7];
    if (regon.length === 9) {
      return validatePolish.checksum(regon, weights9);
    }
    const weights14 = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
    return validatePolish.checksum(regon.slice(0, 9), weights9) && validatePolish.checksum(regon, weights14);
  },
  identityCard(num) {
    if (!num || num.length !== 9) {
      return false;
    }
    const upperNum = num.toUpperCase();
    const letterValues = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z"
    ];
    const getLetterValue = (letter) => {
      for (let j = 0, max = letterValues.length; j < max; j++) {
        if (letter === letterValues[j]) {
          return j;
        }
      }
      return -1;
    };
    for (let i = 0; i < 3; ++i) {
      if (getLetterValue(upperNum[i]) < 10) {
        return false;
      }
    }
    for (let i = 3; i < 9; ++i) {
      if (getLetterValue(upperNum[i]) < 0 || getLetterValue(upperNum[i]) > 9) {
        return false;
      }
    }
    let sum = 7 * getLetterValue(upperNum[0]) + 3 * getLetterValue(upperNum[1]) + Number(getLetterValue(upperNum[2])) + 7 * getLetterValue(upperNum[4]) + 3 * getLetterValue(upperNum[5]) + Number(getLetterValue(upperNum[6])) + 7 * getLetterValue(upperNum[7]) + 3 * getLetterValue(upperNum[8]);
    sum %= 10;
    if (sum !== getLetterValue(upperNum[3])) {
      return false;
    }
    return true;
  },
  identityCardWithSeparator(num) {
    if (!num || num.length !== 10) {
      return false;
    }
    if (num[3] !== " " && num[3] !== "-") {
      return false;
    }
    return this.identityCard(num.replace(/[\s-]/gu, ""));
  }
};

// src/helpers/validateAgreementNo.ts
var validateAgreementNo = (val) => /^GUS\/(\d{4}\/\d{2})\/(\d{3,5})\/([A-Z]{2}\d{2})$/.test(val);
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  formatCurrency,
  formatIban,
  formatIbanWithoutSpaces,
  formatPhoneNumber,
  formatPhoneNumberWithoutSeparator,
  validateAgreementNo,
  validateIban,
  validatePolish
});
