export enum ContractGenerationDataFields {
  nameAndLastName = 'nameAndLastName',
  pesel = 'pesel',
  identityCardNumber = 'identityCardNumber',
  identityCardValidFrom = 'identityCardValidFrom',
  identityCardValidTo = 'identityCardValidTo',
  returnAccountNumber = 'returnAccountNumber',
  buyerPhone = 'buyerPhone',
}

export type ContractGenerationDataFieldsType = {
  [ContractGenerationDataFields.nameAndLastName]: string
  [ContractGenerationDataFields.pesel]: string
  [ContractGenerationDataFields.identityCardValidFrom]: string
  [ContractGenerationDataFields.identityCardValidTo]: string
  [ContractGenerationDataFields.returnAccountNumber]: string
  [ContractGenerationDataFields.buyerPhone]: string
}

export enum ContractGenerationEditableFields {
  returnAccountNumber = 'returnAccountNumber',
}

export enum ContractGenerationDataConsentsFields {
  selectAll = 'selectAll',
  agreementAdministrator = 'agreementAdministrator',
  agreementContractor = 'agreementContractor',
}

export enum ContractGeneratedDataFields {
  agreementGenerated = 'agreementGenerated',
  agreementAcceptance = 'agreementAcceptance',
}

export enum ReplacementAgreementFields {
  replacementAgreement = 'replacementAgreement',
  replacementAgreementVerified = 'replacementAgreementVerified',
  replacementAgreementNumber = 'replacementAgreementNumber',
}
