import ApiEndpoints from 'api/endpoints'
import dayjs from 'dayjs'
import { OnError, OnSuccess, useMutate } from 'hooks'
import { IAgreementResponse, ICustomerOrder } from 'models'

import { ContractGenerationValidationSchema } from '../ContractGeneration/consts'

export const usePostGenerateOrderContract = (token: string, onError: OnError, onSuccess: OnSuccess) => {
  return useMutate<IAgreementResponse>(ApiEndpoints.postGenerateOrderContract(token), {
    onError,
    onSuccess,
  })
}

export const prepareGenerateOrderContractData = (
  formData: ContractGenerationValidationSchema,
  originalOrderProducts: ICustomerOrder | null | undefined,
) => {
  const getDateFormatted = (date: any) => {
    if (typeof date === 'string' && date.includes('*')) {
      return date
    } else return dayjs(date).format('YYYY-MM-DD')
  }
  const data = {
    uuid: originalOrderProducts?.externalId,
    pesel: formData.pesel,
    nip: originalOrderProducts?.nip,
    email: originalOrderProducts?.buyerEmail,
    phoneNumber: formData?.buyerPhone,
    identityCardNumber: formData.identityCardNumber,
    identityCardValidFrom: getDateFormatted(formData.identityCardValidFrom),
    identityCardValidTo: getDateFormatted(formData.identityCardValidTo),
    nameAndLastName: formData.nameAndLastName,
    returnAccountNumber: formData.returnAccountNumber,
    identityCardValidIndefinitely: false,
    customerOrderType: 'B2B',
  }
  return data
}
