import ApiEndpoints from 'api/endpoints'
import { STORAGE_SESSION_ID } from 'consts'
import { useMutate } from 'hooks'
import { ICreateCustomerOrder, ICustomerOrder, IMappedProduct } from 'models'

import { ValidationSchema } from '../PersonalData/consts'

export const usePostCreateOrder = (token: string) => {
  return useMutate<ICustomerOrder>(ApiEndpoints.postCreateOrder(token))
}

type ApiData = {
  apiBannerParam: string
  bannerGeneratedId: string
  applicationName: 'BN' | 'API'
  vendorId: number | undefined
}

export const prepareCreateOrderData = (
  orderId: string,
  formData: ValidationSchema,
  orderProductsData: IMappedProduct[],
  apiData: ApiData,
) => {
  const products = orderProductsData.map(item => ({
    id: item.id,
    quantity: item.quantity,
    safeUpPlusTaken: item.safeupPlusTaken,
  }))

  const itemWithPromo = orderProductsData.find(el => el.discountCode)

  const promotion = itemWithPromo
    ? {
        id: itemWithPromo.discountId,
        code: itemWithPromo.discountCode ?? null,
        // is ignored on BE
        percent: itemWithPromo.discount ?? null,
      }
    : null

  const sessionId = localStorage.getItem(STORAGE_SESSION_ID)

  const data = {
    nip: formData.nip,
    email: formData.buyerEmail,
    phone: formData.buyerPhone,
    firstName: formData.firstName,
    lastName: formData.lastName,
    externalOrderId: orderId,

    products,
    promotion,

    // agreements
    rodoAgreementAccepted: formData.agreementRodo,
    statuteAccepted: formData.agreementStatute,
    krdAccepted: formData.agreementKrd,
    electronicProcessingAccepted: formData.agreementEmailCommunication,
    phoneProcessingAccepted: formData.agreementPhoneCommunication,

    sessionId: sessionId ?? '',

    ...apiData,
  }
  return data as ICreateCustomerOrder
}
